import React from 'react';
import {PageWrapper} from 'components/Common/PageWrapper/index';
import GameGenresBlock from 'components/HomePage/GameGenresBlock';
import RecordsBlock from 'components/Common/RecordsBlock';
import {MainContentWrapper} from 'components/Common/MainContentWrapper/index';
import GamesContainer from 'components/HomePage/GamesContainer';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { useSelector, useDispatch } from 'react-redux';
import { authSelector, getInfo } from 'redux/authReducer';
import { getCurrentPoll } from 'redux/pollsReducer';
import MainPreloader from '../components/Common/MainPreloader';





const Home = ({pusherClient}) => {
  const dispatch = useDispatch()

  const {
    authInfo: {info}, status,
  } = useSelector(authSelector);

  React.useEffect(() => {
    status === '' && dispatch(getInfo());
  }, [dispatch, status]);
  
  React.useEffect(() => {
    if(status === 'ok') {
      info === undefined && dispatch(getInfo())
      dispatch(getCurrentPoll());
    }
    // eslint-disable-next-line
  }, [dispatch, status]);

  return status ? (
    <>
      <Header pusherClient={pusherClient}/>
      <PageWrapper margin="30px 0 0 0">
        <MainContentWrapper>
          <GameGenresBlock />
          <GamesContainer />
        </MainContentWrapper>
        <RecordsBlock />
      </PageWrapper>
      <Footer />
    </>
  ) : <MainPreloader />;
};

export default Home;
