import {io} from "socket.io-client";

class Pusher {
    constructor(key, info) {
        this.key = key;
        this.channels = {};
        this.server = "https://local.test-millenium.ga:7778";
        this.authEndpoint = info.authEndpoint || null;
        this.auth = info.auth || null;
        this.authCompleted = false;
        this.startQueue = [];
        this.channels = {};
        this.connect();
    }

    authConnect(token = null) {
        const opts = {auth: {key: this.key}};
        if (token) {
            opts.auth["token"] = token;
        }
        this.socket = io(this.server, opts);
        this.socket.on("connect", () => {
            this.authCompleted = true;
            this.startQueue.forEach(f => f());
            this.startQueue = [];
        });
    }

    connect() {
        if (this.authEndpoint) {
            const xhr = new XMLHttpRequest();
            xhr.open("POST", this.authEndpoint);
            if (this.auth && this.auth.headers) {
                for (let prop in this.auth.headers) {
                    xhr.setRequestHeader(prop, this.auth.headers[prop]);
                }
            }
            xhr.onload = () => {
                console.log(xhr.status, xhr.response);
                try {
                    const data = JSON.parse(xhr.response);
                    if (xhr.status === 200 && data.id) {
                        if (data.server) {
                            this.server = data.server;
                        }
                        this.authConnect(data.id);
                    }
                } catch (e) {
                    console.log(e); // error in the above string (in this case, yes)!
                }
            };
            xhr.async = true;
            xhr.send(null);
        } else {
            this.authConnect();
        }
    }

    subscribe(channelName) {
        return {
            bind: (messageType, f) => this.bind(channelName, messageType, f),
            unbind: (messageType) => this.unbind(channelName, messageType)
        };
    }

    bind(channelName, messageType, f) {
        const fn = () => {
            if (!(channelName in this.channels)) {
                this.channels[channelName] = {};
                this.socket.emit("subscribe", channelName);
                console.log("subscribe", channelName, this.socket.id)
            }
            if (!(messageType in this.channels[channelName])) {
                this.channels[channelName][messageType] = f;
                this.socket.on(messageType, (data) => {
                    console.log(channelName, messageType, data);
                    f(data);
                });
            }
            console.log("subscribe", channelName, this.channels[channelName])
        };
        this.authCompleted ? fn() : this.startQueue.push(fn);
    }

    unbind(channelName, messageType) {
        const fn = () => {
            if (!(channelName in this.channels)) {
                return;
            }
            if (!(messageType in this.channels[channelName])) {
                return;
            }
            delete this.channels[channelName][messageType];
        };
        this.authCompleted ? fn() : this.startQueue.push(fn);
    }
};

Pusher.Runtime = {createXHR: null};
export default Pusher;