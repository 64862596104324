import {AUTH_API} from 'settings';

const changeDenom = async (denomValue) => {
  const URI = `${AUTH_API}/json/denomination?value=${denomValue}`;
  const query = {
    method: 'GET',
    credentials: 'include',
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  };

  const response = await fetch(URI, query);
  const json = await response.json();
  return json;
};

export default {changeDenom};
