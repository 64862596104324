import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {ThemeProvider} from 'styled-components';
// import * as serviceWorker from './serviceWorker'
import App from './routes';

import GlobalStyle from 'theme/GlobalStyles';
import store from 'redux/store';
import {theme} from 'theme/theme';

export const history = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <App history={history} />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}

// serviceWorker.register()
