import React from 'react';
import {Wrapper, Header, ButtonsWrapper, Button} from './styles';
import RemoteIn from '../RemoteIn';
import {useSelector, useDispatch} from 'react-redux';
import {authSelector, getInfo} from 'redux/authReducer';
import {withRouter} from 'react-router-dom';

const Menu = ({history}) => {
  const dispatch = useDispatch();
  const {
    authInfo: {info},
  } = useSelector(authSelector);

  React.useEffect(() => {
    info === null && dispatch(getInfo());
  }, [info, dispatch]);

  const menuItems = [
    'ATTENDANT',
    'ACCOUNTING',
    'DIAGNOSTIC',
    'EVENT LOG',
    'GAME HISTORY',
    'GAME STATISTIC',
  ];

  const ATTENDANT = ['REMOTE IN', 'HANDPAY CREDIT', 'OUT OF SERVICE'];
  const [currentMenuItem, setCurrentMenuItem] = React.useState('');
  const [currentSubMenuItem, setCurrentSubMenuItem] = React.useState('');

  const [selectedMenuItem, setSelectedMenuItem] = React.useState(0);

  const currentItems = currentMenuItem ? ATTENDANT : menuItems;

  const handleKeyPress = (e) => {
    const {code} = e;
    const {select_first_game, select_fifth_game, previous_game, next_game} =
      info && info.hotkeys;
    previous_game.forEach((item) => {
      if (code === item) {
        if (selectedMenuItem > 0) {
          setSelectedMenuItem(selectedMenuItem - 1);
        } else {
          setSelectedMenuItem(currentItems.length - 1);
        }
      }
    });

    next_game.forEach((item) => {
      if (code === item) {
        if (selectedMenuItem !== currentItems.length - 1) {
          setSelectedMenuItem(selectedMenuItem + 1);
        } else {
          setSelectedMenuItem(0);
        }
      }
    });

    select_fifth_game.forEach((item) => {
      if ((code === item || code === 'ArrowRight') && selectedMenuItem === 0) {
        currentMenuItem
          ? setCurrentSubMenuItem(currentItems[selectedMenuItem])
          : setCurrentMenuItem(currentItems[selectedMenuItem]);
        setSelectedMenuItem(0);
      }
    });

    select_first_game.forEach((item) => {
      if (code === item && !currentSubMenuItem) {
        currentMenuItem ? setCurrentMenuItem('') : history.push('/authgames');
      }
    });
  };

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
    // eslint-disable-next-line
  }, [selectedMenuItem, currentSubMenuItem, currentMenuItem, info, history]);

  return (
    <Wrapper>
      {currentSubMenuItem ? (
        <RemoteIn
          info={info}
          currentMenuItem={currentMenuItem}
          currentSubMenuItem={currentSubMenuItem}
          setCurrentSubMenuItem={setCurrentSubMenuItem}
        />
      ) : (
        <>
          <Header>
            <span>AUDIT MENU</span>
          </Header>
          <div className="menu-wrapper">
            <ul className="menu">
              {menuItems.map((item, id) => {
                const disabledClass = () => {
                  if (currentMenuItem) {
                    return currentMenuItem !== item ? 'disabled' : '';
                  }
                };
                return (
                  <li
                    key={id}
                    className={`${
                      id === selectedMenuItem && !currentMenuItem
                        ? 'selected'
                        : ''
                    } ${disabledClass()}`}
                  >
                    {item}
                  </li>
                );
              })}
            </ul>
            {currentMenuItem ? (
              <ul className="menu">
                {currentMenuItem === 'ATTENDANT' &&
                  ATTENDANT.map((item, id) => {
                    return (
                      <li
                        key={id}
                        className={
                          id === selectedMenuItem && currentMenuItem
                            ? 'selected'
                            : ''
                        }
                      >
                        {item}
                      </li>
                    );
                  })}
              </ul>
            ) : (
              ''
            )}
            <ButtonsWrapper>
              <div>
                <Button>
                  <span>BACK</span>
                </Button>
              </div>
              <div>
                <Button>
                  <span>NEXT</span>
                </Button>
              </div>
            </ButtonsWrapper>
          </div>
        </>
      )}
    </Wrapper>
  );
};

export default withRouter(Menu);
