import {AUTH_API} from 'settings';

const fetchInfo = async () => {
  const URI = `${AUTH_API}/json`;
  const query = {
    method: 'GET',
    credentials: 'include',
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  };

  const response = await fetch(URI, query);
  const json = await response.json();
  return json;
};

const fetchRecords = async () => {
  const URI = `${AUTH_API}/json/highscores`;
  const query = {
    method: 'GET',
    credentials: 'include',
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  };

  const response = await fetch(URI, query);
  const json = await response.json();
  return json;
};

const login = async ({password, login}) => {
  const URI = `${AUTH_API}/login?action=login&login=${login}&password=${password}`;
  const query = {
    method: 'GET',
    credentials: 'include',
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  };

  const response = await fetch(URI, query);
  const json = await response.json();
  return json;
};

const collect = async () => {
  const URI = `${AUTH_API}/json/collect`;
  const query = {
    method: 'GET',
    credentials: 'include',
  };

  const response = await fetch(URI, query);
  const json = await response.json();
  return json;
};

const remoteIn = async (credits) => {
  const URI = `${AUTH_API}/json/requestMoney?sum=${credits}`;
  const query = {
    method: 'GET',
    credentials: 'include',
  };

  const response = await fetch(URI, query);
  const json = await response.json();
  return json;
};

export const getGame = async () => {
  const URI = `${AUTH_API}/json/getGame`;
  const query = {
    method: 'GET',
    credentials: 'include',
  };

  const response = await fetch(URI, query);
  const json = await response.json();
  return json;
};

export default {
  fetchInfo,
  login,
  fetchRecords,
  collect,
  remoteIn,
};
