import {createSlice} from '@reduxjs/toolkit';
import {polls} from 'services';

export const slice = createSlice({
  name: 'polls',
  initialState: {
    currentQuestion: 0,
    questionAnsweredLoading: false,
    isPollDone: {
      done: false,
      loading: false,
      error: null,
    },
    currentPoll: {
      data: null,
      loading: false,
      error: null,
    },
    newPoll: {
      data: null,
      loading: false,
      error: null,
    },
    isQuestionAnswered: {
      answered: false,
      loading: false,
      error: null,
    },
    isPollFinished: {
      finished: false,
      loading: false,
      error: null,
      data: null,
    },
  },
  reducers: {
    getPollsIsLoading: (state) => {
      state.loading = true;
    },
    getPollsSuccess: (state, {payload}) => {
      state.items = payload;
      state.loading = false;
      state.error = null;
    },
    getPollsFailure: (state, {payload}) => {
      state.loading = false;
      state.error = payload;
    },
    answerQuestionIsLoading: (state) => {
      state.isPollDone.loading = true;
    },
    answerQuestionSuccess: (state, {payload}) => {
      state.isPollDone.done = payload;
      state.isPollDone.loading = false;
      state.isPollDone.error = null;
    },
    answerQuestionFailure: (state, {payload}) => {
      state.isPollDone.loading = false;
      state.isPollDone.error = payload;
    },
    currentPollIsLoading: (state) => {
      state.currentPoll.loading = true;
    },
    currentPollSuccess: (state, {payload}) => {
      state.currentPoll.data = payload;
      state.currentPoll.loading = false;
      state.currentPoll.error = null;
    },
    currentPollFailure: (state, {payload}) => {
      state.currentPoll.loading = false;
      state.currentPoll.error = payload;
    },
    newPollIsLoading: (state) => {
      state.newPoll.loading = true;
    },
    newPollSuccess: (state, {payload}) => {
      state.newPoll.data = payload;
      state.newPoll.loading = false;
      state.newPoll.error = null;
    },
    newPollFailure: (state, {payload}) => {
      state.newPoll.loading = false;
      state.newPoll.error = payload;
    },
    isQuestionAnsweredIsLoading: (state) => {
      state.isQuestionAnswered.loading = true;
    },
    isQuestionAnsweredSuccess: (state) => {
      state.isQuestionAnswered.answered = true;
      state.isQuestionAnswered.loading = false;
      state.isQuestionAnswered.error = null;
    },
    isQuestionAnsweredFailure: (state, {payload}) => {
      state.isQuestionAnswered.loading = false;
      state.isQuestionAnswered.error = payload;
      state.isQuestionAnswered.answered = false;
    },
    isPollFinishedIsLoading: (state) => {
      state.isPollFinished.loading = true;
    },
    isPollFinishedSuccess: (state, {payload}) => {
      state.isPollFinished.answered = true;
      state.isPollFinished.data = payload;
      state.isPollFinished.loading = false;
      state.isPollFinished.error = null;
    },
    isPollFinishedFailure: (state, {payload}) => {
      state.isPollFinished.loading = false;
      state.isPollFinished.error = payload;
      state.isPollFinished.answered = false;
    },
    setCurrentQuestion: (state, {payload}) => {
      state.currentQuestion = payload
    },
    setQuestionAnsweredLoading: (state, {payload}) => {
      state.questionAnsweredLoading = payload
    },
  },
});

export function getPolls() {
  return async (dispatch) => {
    dispatch(getPollsIsLoading());

    try {
      const response = await polls.fetchPolls();
      dispatch(getPollsSuccess(response.data));
    } catch (error) {
      dispatch(getPollsFailure(error.message));
    }
  };
}

export function getPollDone(answerId) {
  return async (dispatch) => {
    dispatch(answerQuestionIsLoading());

    try {
      const response = await polls.fetchPollQuestion(answerId);
      dispatch(answerQuestionSuccess(response.success));
    } catch (error) {
      dispatch(answerQuestionFailure(error.message));
    }
  };
}
export function getCurrentPoll() {
  return async (dispatch) => {
    dispatch(currentPollIsLoading());

    try {
      const response = await polls.fetchCurrentPoll();
      
      let currentQuestions = 0
      if(!!response.data.questions.length) {
        response.data.questions.forEach((item, id) => {
          if(item.selected !== null) {
            currentQuestions += 1
          }
        })
      } else {
        currentQuestions = -1
      }
  
      dispatch(setCurrentQuestion(currentQuestions))
      dispatch(currentPollSuccess(response.data));
    } catch (error) {
      dispatch(setCurrentQuestion(-1))
      dispatch(currentPollFailure(error.message));
    }
  };
}
export function getNewPoll() {
  return async (dispatch) => {
    dispatch(newPollIsLoading());
    dispatch(setCurrentQuestion(0))
    try {
      const response = await polls.fetchNewPoll();
      dispatch(currentPollSuccess(response.data));
    } catch (error) {
      dispatch(newPollFailure(error.message));
    }
  };
}
export function answerQuestion({questionId, answerId, currentQuestion}) {
  return async (dispatch) => {
    dispatch(isQuestionAnsweredIsLoading());
    dispatch(setQuestionAnsweredLoading(true));
    dispatch(setCurrentQuestion(currentQuestion + 1));

    try {
      const response = await polls.fetchAnsweredQuestion({
        questionId,
        answerId,
      });

      if (response.status === 'ok') {
        dispatch(currentPollSuccess(response.data))
        dispatch(setQuestionAnsweredLoading(false));
      } else if (response.status === 'error') {
        dispatch(setQuestionAnsweredLoading(false));
      }
    } catch (error) {
      dispatch(setQuestionAnsweredLoading(false));
      dispatch(setCurrentQuestion(-1));
    }
  };
}

export function finishPoll() {
  return async (dispatch) => {
    dispatch(isPollFinishedIsLoading());
    try {
      const response = await polls.fetchFinishPoll();
      dispatch(isPollFinishedSuccess(response.data));
      dispatch(currentPollSuccess(null));
    } catch (error) {
      dispatch(isPollFinishedFailure(error.message));
    }
  };
}


export const {
  getPollsIsLoading,
  getPollsSuccess,
  getPollsFailure,
  answerQuestionIsLoading,
  answerQuestionFailure,
  answerQuestionSuccess,
  currentPollFailure,
  currentPollSuccess,
  currentPollIsLoading,
  newPollFailure,
  newPollSuccess,
  newPollIsLoading,
  isQuestionAnsweredFailure,
  isQuestionAnsweredIsLoading,
  isQuestionAnsweredSuccess,
  isPollFinishedFailure,
  isPollFinishedIsLoading,
  isPollFinishedSuccess,
  setCurrentQuestion,
  setQuestionAnsweredLoading
} = slice.actions;

export const pollsSelector = (state) => state.polls;

export default slice.reducer;
