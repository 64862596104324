import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {withRouter} from 'react-router-dom';

import {MobileMenuContainer, StyledMobileMenu, MobileMenuItem} from './styles';

import {useLockBodyScroll} from 'hooks/useLockBodyScroll';

const MOBILE_LINKS = [
  {id: 1, to: '/', text: 'Игры'},
  {id: 2, to: '/news', text: 'Новости'},
  {id: 3, to: '/youthpolitics', text: 'Молодёжная политика'},
  {id: 4, to: '/table', text: 'Турнирная таблица'},
];

const MobileMenu = ({closeMobileMenu, history}) => {
  useLockBodyScroll();

  const proceedToPage = (path) => {
    history.push(path);
    closeMobileMenu();
  };
  return (
    <MobileMenuContainer>
      <StyledMobileMenu>
        <CloseIcon className="closemobilemenu" onClick={closeMobileMenu} />
        {MOBILE_LINKS.map((item) => (
          <MobileMenuItem key={item.id} onClick={() => proceedToPage(item.to)}>
            {item.text}
          </MobileMenuItem>
        ))}
      </StyledMobileMenu>
    </MobileMenuContainer>
  );
};

export default withRouter(MobileMenu);
