import React from 'react';

import {PageWrapper} from 'components/Common/PageWrapper/index';
import RecordsBlock from 'components/Common/RecordsBlock';
import YouthPoliticsContainer from 'components/YouthPolitcsPage/YouthPoliticsContainer';
import Header from 'components/Header';
import Footer from 'components/Footer';
import MainPreloader from 'components/Common/MainPreloader';
import { getInfo, authSelector } from 'redux/authReducer';
import { useDispatch, useSelector } from 'react-redux';

const Home = () => {
  const dispatch = useDispatch()
  const {
    status,
  } = useSelector(authSelector);

  React.useEffect(() => {
    status === '' && dispatch(getInfo());
  }, [dispatch, status]);

  return status ? (
    <>
      <Header />
      <PageWrapper margin="30px 0 0 0">
        <YouthPoliticsContainer />
        <RecordsBlock />
      </PageWrapper>
      <Footer />
    </>
  ) : <MainPreloader/>
};

export default Home;
