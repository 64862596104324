import React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {GameGenresBlockContainer} from './styles';

import ShadowText from 'components/Common/ShadowText';
import GenreButton from './GenreButton';

import {getGenres, genresSelector} from 'redux/genresReducer';
import { setSelectedGames } from 'redux/gamesReducer';

const ALL_GENRES_BUTTON = {title: 'Все'}

const ALL_GENRES_INDEX = -1;

const GameGenresBlock = () => {
  const dispatch = useDispatch();
  const {items: genres, loading} = useSelector(genresSelector);
  const [selectedGenre, setSelectedGenre] = React.useState(ALL_GENRES_INDEX);

  React.useEffect(() => {
    if(genres.length === 0) dispatch(getGenres())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <GameGenresBlockContainer>
      <ShadowText text="Жанры игр" margin="0 0 7px 0" />
      {loading && 'Loading...'}
      {!loading &&
        genres.length > 0 &&
        genres.map((genre, index) => (
          <GenreButton
            key={genre.id}
            genre={genre}
            onClick={() => {
              dispatch(setSelectedGames(genre.title))
              setSelectedGenre(index)
            }}
            active={index === selectedGenre}
          />
        ))}
      {!loading && (
        <GenreButton
          genre={ALL_GENRES_BUTTON}
          onClick={() => {
            dispatch(setSelectedGames(''))
            setSelectedGenre(ALL_GENRES_INDEX)
          }}
          active={ALL_GENRES_INDEX === selectedGenre}
        />
      )}
    </GameGenresBlockContainer>
  );
};

export default GameGenresBlock;
