import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { splitSlidesData } from 'utils/helpers';
import frame from 'assets/images/frame.png';

import { MainContentContainer, GameContainer, GameImg } from './styles';

import { authSelector } from 'redux/authReducer';
import {
  mainContentSelector,
  onChangeSlide,
  setSelectorProvider,
  setSliderCount,
} from 'redux/mainContentReducer';
import arrowLeft from 'assets/images/arrowup.png';
import arrowRight from 'assets/images/arrowdown.png';

import select from 'assets/sounds/Select.wav';
import enter from 'assets/sounds/Enter.wav';
import { soundPlayer } from 'utils/soundPlayer';
import { objectLength } from 'utils/objectLength';

const MainContent = ({
  isRulesShown,
  isOfertaShown,
  isPollShown,
  isRecordsShown,
  gameMenu,
  setSelectedGames,
  selectedGames,
  isChangeHotkeyShown,
}) => {
  const dispatch = useDispatch();

  const {
    authInfo: { info },
  } = useSelector(authSelector);

  const isSocialPoll = info && info.is_social_poll;

  const {sliderCount, selectedProvider} = useSelector(mainContentSelector);
  const avalableGames = (games) => {
  const game_package_list = info?.game_package_list
    let newGames = [];
    game_package_list && games && games.forEach((game) => {
      if (game_package_list[game.code]) {
        newGames = [
          ...newGames,
          {
            code: game.code,
            logo: game.logo,
          },
        ];
      }
    })
    return newGames;
  };

  const sliderElementsSum = 8;
  const games =
    info && info.game_list
      ? info.game_list.filter((item) => item.package_name === selectedProvider)
      : [];
  const allGames = selectedProvider ? games : avalableGames(gameMenu);
  const gamesList = splitSlidesData(allGames, sliderElementsSum);

  const [sliderPosition, setSliderPosotion] = useState({});

  const selectedLine =
    (sliderCount * -1 * sliderElementsSum) / 4 + (selectedGames === 4 ? 1 : 0);
  const selectedLinksCount = 4;
  const gamesLineSumRemains = allGames.length % 4 ? 1 : 0;
  const gamesLineSum = Math.floor(allGames.length / 4) + gamesLineSumRemains;

  const gamesLinks = (array) => {
    const startArray = selectedLine * selectedLinksCount;
    const endArray = selectedLine * selectedLinksCount + selectedLinksCount;

    return array.slice(startArray, endArray);
  };

  useEffect(() => {
    setSliderPosotion({
      transform: `translateX(${100 * -sliderCount}%)`,
    });
  }, [sliderCount]);

  const hotkeys = info && info.hotkeys;
  const handleKeyPress = (e) => {
    if (
      !isRulesShown &&
      !isOfertaShown &&
      !isPollShown &&
      !isRecordsShown &&
      !isChangeHotkeyShown
    ) {
      const { code } = e;
      const {
        previous_game,
        next_game,
        select_first_game,
        select_second_game,
        select_third_game,
        select_fourth_game,
        previous_provider,
      } = hotkeys;

      //select provider
      previous_provider.forEach((item) => {
        if (code === item) {
          if (selectedProvider) {
            dispatch(setSelectorProvider(''));
            dispatch(setSliderCount(0));
          }
        }
      });

      //arrow UP
      previous_game.forEach((item) => {
        if (code === item) {
          soundPlayer(select).play();
          if (selectedGames >= 4) {
            setSelectedGames(selectedGames - 4);
          } else {
            selectedProvider &&
              dispatch(onChangeSlide(-1, gamesList, sliderCount));
            setSelectedGames(selectedGames + 4);
          }
        }
      });

      //arrow DOWN
      // next_game.forEach((item) => {
      if (code === next_game[0]) {
        soundPlayer(select).play();
        if (selectedGames <= 0) {
          selectedLine <= gamesLineSum - 1 &&
            setSelectedGames(selectedGames + 4);
        } else {
          selectedProvider &&
            dispatch(onChangeSlide(1, gamesList, sliderCount));
          setSelectedGames(selectedGames - 4);
        }
      }
      // });

      const redirectOnGame = (num) => {
        document.location.href = allGames[sliderCount * 8 + num].url;
      };
      const disabledKey = (num) =>
        gamesList[sliderCount].slice(
          selectedGames,
          selectedGames + selectedLinksCount
        ).length >= num;

      //btn 1
      select_first_game.forEach((item) => {
        if (code === item && disabledKey(1)) {
          soundPlayer(enter).play();
          selectedProvider
            ? redirectOnGame(0 + selectedGames)
            : dispatch(
              setSelectorProvider(
                avalableGames(gameMenu)[0 + selectedGames].code
              )
            );
        }
      });

      //btn 2
      select_second_game.forEach((item) => {
        if (code === item && disabledKey(2)) {
          soundPlayer(enter).play();
          selectedProvider
            ? redirectOnGame(1 + selectedGames)
            : dispatch(
              setSelectorProvider(
                avalableGames(gameMenu)[1 + selectedGames].code
              )
            );
        }
      });
      //btn 3
      select_third_game.forEach((item) => {
        if (code === item && disabledKey(3)) {
          soundPlayer(enter).play();
          selectedProvider
            ? redirectOnGame(2 + selectedGames)
            : dispatch(
              setSelectorProvider(
                avalableGames(gameMenu)[2 + selectedGames].code
              )
            );
        }
      });
      //btn 4
      select_fourth_game.forEach((item) => {
        if (code === item && disabledKey(4)) {
          soundPlayer(enter).play();
          selectedProvider
            ? redirectOnGame(3 + selectedGames)
            : dispatch(
              setSelectorProvider(
                avalableGames(gameMenu)[3 + selectedGames].code
              )
            );
        }
      });
    }
  };

  useEffect(() => {
    if (hotkeys) {
      document.addEventListener('keydown', handleKeyPress);

      return () => {
        document.removeEventListener('keydown', handleKeyPress);
      };
    }
    // eslint-disable-next-line
  }, [
    selectedGames,
    gamesLinks,
    sliderCount,
    selectedLine,
    hotkeys,
    isRulesShown,
    isOfertaShown,
    isPollShown,
    isChangeHotkeyShown,
  ]);

  useEffect(() => {
    if (objectLength(info && info.game_package_list) === 1) {
      for (let name in info.game_package_list) {
        dispatch(setSelectorProvider(name));
      }
    }
    // eslint-disable-next-line
  }, []);
  const renderSlide = (slideData) => {
    return slideData.map((game, id) => {
      let selectedLine = id >= selectedGames && id <= selectedGames + 3;
      return (
        <div
          key={uuidv4()}
          className={`game ${selectedLine ? 'selected-game' : ''}`}
          onClick={() => {
            soundPlayer(enter).play();
            document.location.href = game.url;
          }}
        >
          <GameContainer>
            <img alt="frame" className="frame" src={frame} />
            <GameImg>
              {selectedLine ? (
                <div className={'selected-label'}>
                  <span>Играть</span>
                </div>
              ) : (
                  ''
                )}
              <img src={game.logo} alt="game" />
            </GameImg>
          </GameContainer>
        </div>
      );
    });
  };
  const renderMenu = (data) => {
    return data.map((provider, id) => {
      let selectedLine = id >= selectedGames && id <= selectedGames + 3;
      return (
        <div
          key={uuidv4()}
          className={`game ${selectedLine ? 'selected-provider' : ''}`}
          onClick={() => {
            soundPlayer(enter).play();
            dispatch(setSelectorProvider(provider.code));
          }}
        >
          <div className="game-provider">
            {selectedLine ? (
              <div className={'selected-label'}>
                <span>Выбор</span>
              </div>
            ) : (
                ''
              )}
            {provider.logo ? (
              <img src={provider.logo} alt="provider" />
            ) : (
                <>
                  <h4 style={{ color: '#e1b057', fontSize: '2.8vh' }}>
                    {provider.code}
                  </h4>
                  <img
                    src={frame}
                    alt="no-img"
                    style={{ width: '91%', height: 'auto' }}
                  />
                </>
              )}
          </div>
        </div>
      );
    });
  };

  const prevSlide = isSocialPoll && (
    <div
      className="game-slider-button-left"
      // style={{display: sliderCount > 0 ? 'block' : 'none'}}
      onClick={() => dispatch(onChangeSlide(-1, gamesList, sliderCount))}
    >
      <img className="game-slider-button-l" src={arrowLeft} alt="arrowLeft" />
    </div>
  );

  const nextSlide = isSocialPoll && (
    <div
      className="game-slider-button-right"
      // style={{
      //   display: sliderCount > -(allGames.length / 8) + 1 ? 'block' : 'none',
      // }}
      onClick={() => {
        selectedGames === 4 && setSelectedGames(selectedGames - 4);
        dispatch(onChangeSlide(1, gamesList, sliderCount));
      }}
    >
      <img className="game-slider-button-r" src={arrowRight} alt="arrowRight" />
    </div>
  );

  const rootRender = () => {
    return selectedProvider ? (
      gamesList.map((games) => (
        <div className="slide" key={uuidv4()}>
          <div className="slide-wrapper">
            {renderSlide(games)}
          </div>
        </div>
      ))
    ) : (
      gamesList.map((games) => (
        <div className="slide" key={uuidv4()}>
          <div className="slide-wrapper">
            {renderMenu(games)}
          </div>
        </div>
      ))
    );
  };
  return (
    <MainContentContainer>
      <div className="games-list">
        {prevSlide}
        <div className="game-slides-container" style={sliderPosition}>
          {info && rootRender()}
        </div>
        {nextSlide}
      </div>
    </MainContentContainer>
  );
};

export default MainContent;
