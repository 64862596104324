import React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {
  AuthGamesHeader,
  HeaderNews,
  DenomitationBlock,
  DenominationText,
  DenominationPoints,
  DenominationArrows,
  DenominationArrow,
  NewsWrapper,
  MobileText,
} from './styles';

import denom from 'assets/images/denom.png';
import arrowup from 'assets/images/arrowup.png';
import arrowdown from 'assets/images/arrowdown.png';

import {authSelector} from 'redux/authReducer';
import {changeDenomination} from 'redux/denominationReducer';
import {
  setSelectorProvider,
  setSliderCount,
  mainContentSelector,
} from 'redux/mainContentReducer';
import {soundPlayer} from 'utils/soundPlayer';
import check from 'assets/sounds/Check.wav';

const NewsHeaderContainer = ({
  showOferta,
  showRules,
  balance,
  isOfertaShown,
  isRulesShown,
  isPollShown,
  isRecordsShown,
  isSocialPoll,
}) => {
  const {
    authInfo: {info},
  } = useSelector(authSelector);
  const {selectedProvider} = useSelector(mainContentSelector);

  const dispatch = useDispatch();

  const getActiveDenome = React.useCallback(() => {
    let activeDenome;
    info &&
      info.denom_list.forEach((item, id) => {
        if (Number(item) === Number(info.denom)) activeDenome = id;
      });
    return activeDenome;
  }, [info]);

  const selectDenom = (value) => {
    if (getActiveDenome() + value > info.denom_list.length - 1) {
      dispatch(changeDenomination(info && info.denom_list[0]));
    } else if (getActiveDenome() + value < 0) {
      dispatch(
        changeDenomination(
          info && info.denom_list[info && info.denom_list.length - 1]
        )
      );
    } else {
      dispatch(changeDenomination(info.denom_list[getActiveDenome() + value]));
    }
  };
  const [newsItems, setNewsItems] = React.useState('');
  const [tickerWidth, setTickerWidth] = React.useState(0);
  const [time, setTime] = React.useState(0);

  const string = React.createRef(); //ticker element
  const ticker = React.createRef(); //ticker element
  React.useEffect(() => {
    if (string.current) {
      string.current.onanimationend = () => {
        setNewsItems(info.text);
      };
    }
  }, [info, string, newsItems]);

  React.useEffect(() => {
    if (info)
      setNewsItems(
        info.text
      );
  }, [info, string]);

  React.useEffect(() => {
    if (ticker.current && string.current) {
      setTickerWidth(
        (ticker.current.clientWidth + string.current.clientWidth) / 2
      );
      setTime(
        ((ticker.current.clientWidth - string.current.clientWidth) /
          ticker.current.clientWidth) *
          100
      );
    }
  }, [ticker, string]);

  //buttons control
  const denomChange = (e) => {
    const {next_denomination, previous_denomination} = info && info.hotkeys;
    const {code} = e;
    if (!isOfertaShown && !isRulesShown && !isPollShown && !isRecordsShown) {
      if (code === previous_denomination[0]) {
        soundPlayer(check).play();
        selectDenom(-1);
      } else if (code === next_denomination[0]) {
        soundPlayer(check).play();
        selectDenom(1);
      }
    }
  };

  React.useEffect(() => {
    if (info && info.hotkeys) {
      document.addEventListener('keydown', denomChange);
    }
    return () => {
      document.removeEventListener('keydown', denomChange);
    };
    // eslint-disable-next-line
  }, [info, isOfertaShown, isRulesShown, isPollShown, isRecordsShown]);

  return (
    <>
      {info && (
        <AuthGamesHeader>
          <NewsWrapper>
            <DenomitationBlock>
              {window.innerWidth > 900 && (
                <>
                  <HeaderNews ref={ticker} width={tickerWidth} time={time}>
                    {<span ref={string}>{newsItems}</span>}
                  </HeaderNews>
                  <DenominationText>Деноминация</DenominationText>
                  <DenominationPoints>
                    <span>{info && info.denom}</span>
                    <img src={denom} alt="denom" />
                  </DenominationPoints>
                  <DenominationArrows>
                    <DenominationArrow
                      className="up-arrow"
                      onClick={() => {
                        soundPlayer(check).play();
                        selectDenom(-1);
                      }}
                    >
                      <img src={arrowup} alt="down" />
                    </DenominationArrow>
                    <DenominationArrow
                      onClick={() => {
                        soundPlayer(check).play();
                        selectDenom(1);
                      }}
                    >
                      <img src={arrowdown} alt="down" />
                    </DenominationArrow>
                  </DenominationArrows>
                </>
              )}
              {window.innerWidth < 900 && (
                <>
                  <div className="row">
                  <HeaderNews ref={ticker} width={tickerWidth} time={time}>
                    {<span ref={string}>{newsItems}</span>}
                  </HeaderNews>
                    <MobileText>Деноминация: {info && info.denom}</MobileText>
                    <DenominationArrows>
                      <DenominationArrow
                        className="up-arrow"
                        onClick={() => {
                          soundPlayer(check).play();
                          selectDenom(-1);
                        }}
                      >
                        <img src={arrowup} alt="down" />
                      </DenominationArrow>
                      <DenominationArrow
                        onClick={() => {
                          soundPlayer(check).play();
                          selectDenom(1);
                        }}
                      >
                        <img src={arrowdown} alt="down" />
                      </DenominationArrow>
                    </DenominationArrows>
                  </div>
                  <div className="row">
                    <MobileText
                      onClick={() => {
                        dispatch(setSelectorProvider(''));
                        dispatch(setSliderCount(0));
                      }}
                    >
                      <span style={{opacity: selectedProvider ? '1' : '.5'}}>
                        Производители
                      </span>
                    </MobileText>
                    {isSocialPoll && (
                      <MobileText onClick={showRules}>Правила</MobileText>
                    )}
                    {/* <MobileText>Millenium</MobileText> */}
                    {isSocialPoll && (
                      <MobileText onClick={showOferta}>Оферта</MobileText>
                    )}
                    <MobileText>
                      Очки: {!balance ? info.balance / info.denom : balance}
                    </MobileText>
                  </div>
                </>
              )}
            </DenomitationBlock>
          </NewsWrapper>
        </AuthGamesHeader>
      )}
    </>
  );
};

export default NewsHeaderContainer;
