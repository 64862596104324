
const calclResolutions = ()=>{
  // console.log(window.innerWidth, window.innerHeight);
  if((window.innerWidth === 1680 && window.innerHeight === 1050) || (window.innerWidth === 1920 && window.innerHeight === 1200) || (window.innerWidth === 2560 && window.innerHeight === 1600)){
    return {
      resolution_4_3: `@media (max-width: 300px)`,
      resolution_800_1024: `@media (max-width: 801px) and (min-width: 799px), (max-width: 1025px) and (min-width: 1023px)`,
      resolution_16_10: `
      @media (max-height: 1601px) and (min-height: 1599px),
      (max-width: 1681px) and (min-width: 1679px),
      (max-height: 1201px) and (min-height: 1199px)
      `
    }
  }else{
    return {
      resolution_4_3: `
      @media (max-width: 801px) and (min-width: 799px), 
      (max-width: 1025px) and (min-width: 1023px), 
      (max-height: 961px) and (min-height: 959px),
      (max-width: 1401px) and (min-width: 1399px),
      (max-height: 1201px) and (min-height: 1199px),
      (max-width: 1279px) and (min-width: 1281px),
      (max-height: 1023px) and (min-height: 1025px),
      (max-width: 2049px) and (min-width: 2047px),
      (max-height: 1921px) and (min-height: 1919px),
      (max-width: 1023px) and (min-width: 1025px),
      (max-height: 767px) and (min-height: 769px),
      `,
      resolution_800_1024: `@media (max-width: 801px) and (min-width: 799px), (max-width: 1025px) and (min-width: 1023px)`,

      resolution_16_10: `@media (max-width: 300px)`
    }
  }
}

export const resolutions = calclResolutions();/*{
  //resolution_4_3: `@media (min-width: 800), (max-width: 1024px), (max-width: 1280px), (max-width: 1400px), (min-width: 1600px), (max-width: 2048px), (max-width: 2560px)`,
  resolution_4_3: `
  @media (max-width: 801px) and (min-width: 799px),
  (max-width: 1025px) and (min-width: 1023px),
  (max-height: 961px) and (min-height: 959px),
  (max-width: 1401px) and (min-width: 1399px),
  (max-height: 1201px) and (min-height: 1199px),
  (max-width: 2049px) and (min-width: 2047px),
  (max-height: 1921px) and (min-height: 1919px)
  `,
  resolution_800_1024: `@media (max-width: 801px) and (min-width: 799px), (max-width: 1025px) and (min-width: 1023px)`,

  resolution_16_10: `
  @media (max-height: 1601px) and (min-height: 1599px),
  (max-width: 1681px) and (min-width: 1679px),
  (max-height: 1201px) and (min-height: 1199px)
  `
}*/