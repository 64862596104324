import {API, AUTH_API} from 'settings';

const fetchPolls = async () => {
  const URI = `${API}/v1/political-polls?expand=content,pollOptions&sort=-id&per-page=1&page=1`;
  const query = {
    method: 'GET',
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  };

  const response = await fetch(URI, query);
  const json = await response.json();
  return json;
};
const fetchPollQuestion = async (answerId) => {
  const URI = `${API}/v1/political-polls/vote/${answerId}`;
  const query = {
    method: 'GET',
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  };

  const response = await fetch(URI, query);
  const json = await response.json();
  return json;
};

const fetchCurrentPoll = async () => {
  const URI = `${AUTH_API}/json/pollView`;
  const query = {
    method: 'GET',
    credentials: 'include',
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  };

  const response = await fetch(URI, query);
  const json = await response.json();

  return json;
};
const fetchNewPoll = async () => {
  const URI = `${AUTH_API}/json/pollCreate`;
  const query = {
    method: 'GET',
    credentials: 'include',
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  };

  const response = await fetch(URI, query);
  const json = await response.json();
  return json;
};
const fetchFinishPoll = async () => {
  const URI = `${AUTH_API}/json/pollFinish`;
  const query = {
    method: 'GET',
    credentials: 'include',
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  };

  const response = await fetch(URI, query);
  const json = await response.json();
  return json;
};

const fetchAnsweredQuestion = async ({questionId, answerId}) => {
  const URI = `${AUTH_API}/json/pollSelectAnswer?question_id=${questionId}&answer_id=${answerId} `;
  const query = {
    method: 'GET',
    credentials: 'include',
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  };

  const response = await fetch(URI, query);
  const json = await response.json();
  return json;
};

export default {
  fetchPolls,
  fetchPollQuestion,
  fetchCurrentPoll,
  fetchAnsweredQuestion,
  fetchNewPoll,
  fetchFinishPoll,
};
