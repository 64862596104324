import React from 'react';

import {MainBlockContainer} from './styles';

import MainHeaderContainer from './MainHeader';
import MainContent from './MainContent';

const MainBlock = ({
  showRecords,
  showPoll,
  showRules,
  isRulesShown,
  hideRules,
  showOferta,
  isOfertaShown,
  hideOferta,
  balance,
  handleMouseMove,
  isSocialPoll,
  showLogo,
  isPollShown,
  isRecordsShown,
  setSelectedGames,
  selectedGames,
  gameMenu,
  isChangeHotkeyShown,
}) => {
  return (
    <MainBlockContainer>
      <MainHeaderContainer
        showRules={showRules}
        balance={balance}
        isRulesShown={isRulesShown}
        hideRules={hideRules}
        showOferta={showOferta}
        isOfertaShown={isOfertaShown}
        hideOferta={hideOferta}
        showRecords={showRecords}
        showPoll={showPoll}
        isSocialPoll={isSocialPoll}
        showLogo={showLogo}
      />
      <MainContent
      handleMouseMove={handleMouseMove}
      isSocialPoll={isSocialPoll}
      isRulesShown={isRulesShown}
      isOfertaShown={isOfertaShown}
      isPollShown={isPollShown}
      isRecordsShown={isRecordsShown}
      showPoll={showPoll}
      showRecords={showRecords}
      gameMenu={gameMenu}
      setSelectedGames={setSelectedGames}
      selectedGames={selectedGames}
      isChangeHotkeyShown={isChangeHotkeyShown}
      />
    </MainBlockContainer>
  );
};

export default MainBlock;
