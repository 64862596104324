import {configureStore} from '@reduxjs/toolkit';

import newsReducer from './newsReducer';
import genresReducer from './genresReducer';
import gamesReducer from './gamesReducer';
import recordsReducer from './recordsReducer';
import pollsReducer from './pollsReducer';
import authReducer from './authReducer';
import denominationReducer from './denominationReducer';
import mainContentReducer from './mainContentReducer';

export default configureStore({
  reducer: {
    news: newsReducer,
    genres: genresReducer,
    games: gamesReducer,
    records: recordsReducer,
    polls: pollsReducer,
    auth: authReducer,
    denomination: denominationReducer,
    mainContent: mainContentReducer,
  },
});
