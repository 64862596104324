import {createGlobalStyle} from 'styled-components';
import 'assets/fonts/fonts.css';

const GlobalStyle = createGlobalStyle`
  html{
  scrollbar-width: none;
  }
  body {
		font-family: 'Exo 2', sans-serif;
    box-sizing: border-box;
    background: linear-gradient(270deg, #000A24 22.82%, #0A5AAB 50%, #000A24 76.16%), #000A24;
    font-size: ${(props) => props.theme.fontSizes.text};
    line-height: ${(props) => props.theme.lineHeights.text};
    color: ${(props) => props.theme.colors.white};
   
    ::-webkit-scrollbar {
      width: 0 !important;
      }
    }
  *,
  *::after,
  *::before { 
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  a{
    color: inherit;
    text-decoration: none;
    font-family: inherit;
  }
`;

export default GlobalStyle;
