import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';

import {HeaderWrapper, HeaderContainer} from './styles';

import NavBar from './NavBar';
import LoginModal from 'components/LoginModal';
import MobileMenu from 'components/MobileMenu';

import {useDispatch, useSelector} from 'react-redux';
import {authSelector, setInfoBalance} from 'redux/authReducer';
import {pollsSelector} from 'redux/pollsReducer';
import {withRouter} from 'react-router-dom';

const Header = ({history, pusherClient}) => {
  const dispatch = useDispatch();

  const [isLoginFormShown, setIsLoginFormShown] = React.useState(false);
  const [isMobileMenuShown, setIsMobileMenuShown] = React.useState(false);
  // eslint-disable-next-line
  const showLoginForm = () => setIsLoginFormShown(true);

  const closeLoginForm = () => setIsLoginFormShown(false);

  const showMobileMenu = () => setIsMobileMenuShown(true);

  const closeMobileMenu = () => setIsMobileMenuShown(false);

  const {
    authInfo: {info},
    status,
  } = useSelector(authSelector);
  const { currentQuestion, currentPoll } = useSelector(pollsSelector);

  // const hotkeys = info && info.hotkeys;

  const handlerKeyControl = React.useCallback((e) => {
    if(!isLoginFormShown && e.code === 'Enter') {
      e.preventDefault();
      showLoginForm();
    }
  }, [isLoginFormShown, showLoginForm]);

  React.useEffect(() => {
    window.addEventListener('keydown', handlerKeyControl);
    return () => {
      window.removeEventListener('keydown', handlerKeyControl);
    };
  }, [handlerKeyControl]);

  const InfoBalance = info && info.balance;
  const pollSum = info && info.poll_sum;
  const hall_template = info && info.hall_template;
  const activeMirror = info && info.active_mirror_block;
  const highscore = info && info.highscore

  React.useEffect(() => {
    if (!!info && (!!currentPoll.data || !!currentPoll.error)) {
      if (
        (info && info.balance > 0) ||
        currentQuestion > -1 ||
        (info && info.poll_sum) > 0 ||
        (info && !info.active_mirror_block) ||
        (highscore !== '0.00')
      ) {
        history.push('/authgames');
      }
    }
    if (!!hall_template && hall_template !== 'gold') {
      if (!activeMirror) {
        document.location.href = 'https://nge777.com/';
      } else {
        if (
          activeMirror &&
          currentQuestion === -1 &&
          InfoBalance === '0.00' &&
          pollSum === '0.00'
        ) {
          document.location.href = 'https://nge777.com/';
        }
      }
    }
    // eslint-disable-next-line
  }, [status, info, currentQuestion, currentPoll]);

  React.useEffect(() => {
    if (!!info) {
      const channel =
        pusherClient &&
        pusherClient.subscribe(`private-player-${info && info.id}`);

      pusherClient &&
        channel.bind('change-player', (data) => {
          data.balance !== undefined && dispatch(setInfoBalance(data.balance));
        });
      channel.bind('action-player', (data) => {
        data[0] === 'collect' && history.push('/audit');
      });
      return () =>
        pusherClient &&
        function () {
          channel.unbind('change-player');
          channel.unbind('action-player');
        };
    }
    // eslint-disable-next-line
  }, [dispatch, info]);

  return (
    <HeaderWrapper>
      <LoginModal open={isLoginFormShown} handleClose={closeLoginForm} />
      <HeaderContainer>
        <NavBar status={status} handleOpen={showLoginForm} />
        <MenuIcon className="mobilemenu" onClick={showMobileMenu} />
        {isMobileMenuShown && <MobileMenu closeMobileMenu={closeMobileMenu} />}
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export default withRouter(Header);
