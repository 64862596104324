import React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import footerLeft from 'assets/images/footerleft.png';
import footerRight from 'assets/images/footerright.png';
import selectProvider from 'assets/images/providers/btn.png';
import selectProviderDisabled from 'assets/images/providers/btn_disabled.png';
import footerArrow from 'assets/images/footerArrow.png';

import frame from 'assets/images/modal.png';
import popup from 'assets/images/popup2.png';

import {
  MainFooterContainer,
  FooterImg,
  FooterLeftText,
  FooterRightText,
  MobileFooterText,
  Message,
} from './styles';

import Modal from 'components/AuthGames/Modal';
import Records from '../../Records';

import Poll from '../../Poll';
import {useForm} from 'hooks/useForm';
import StartPoll from '../../StartPoll';
import {authSelector} from 'redux/authReducer';
import {
  mainContentSelector,
  onChangeSlide,
  setSelectorProvider,
  setSliderCount,
} from 'redux/mainContentReducer';
import {pollsSelector, getNewPoll} from 'redux/pollsReducer';
import {withRouter} from 'react-router-dom';
import {objectLength} from 'utils/objectLength';
import {splitSlidesData} from 'utils/helpers';
import ChangeHotkey from '../../ChangeHotkey';

const MainFooter = ({
  showRecords,
  isRecordsShown,
  hideRecords,
  showPoll,
  isPollShown,
  hidePoll,
  isSocialPoll,
  isRulesShown,
  isOfertaShown,
  isCollectShown,
  showCollect,
  gameMenu,
  setSelectedGames,
  selectedGames,
  isChangeHotkeyShown,
  hideChangeHotkey,
}) => {
  const dispatch = useDispatch();
  const {form, handleInputChange} = useForm({isAccepted: false});

  const {
    authInfo: {info},
  } = useSelector(authSelector);

  const gameProviders = (arr, list) => {
    let newArr = [];
    for (let key in list) {
      if (arr.find((i) => i.code === key))
        newArr = [...newArr, arr.find((i) => i.code === key)];
    }
    return newArr;
  };

  const {sliderCount, selectedProvider} = useSelector(mainContentSelector);
  const games =
    info && info.game_list
      ? info.game_list.filter((item) => item.package_name === selectedProvider)
      : [];
  const allGames = selectedProvider ? games : gameProviders(gameMenu, info && info.game_package_list);
  const gamesList = splitSlidesData(allGames, 8);
  const [isPollStarted, setIsPollStarted] = React.useState(false);
  const {
    currentPoll: {data},
    currentQuestion,
  } = useSelector(pollsSelector);

  React.useEffect(() => {
    if (info && info.poll_sum > 0 || info && info.higscore > 0) {
      showPoll();
      setIsPollStarted(true);
    }
    // eslint-disable-next-line
  }, [info]);

  React.useEffect(() => {
    if (currentQuestion > -1 && data && data.questions) {
      showPoll();
      setIsPollStarted(true);
    }
    // eslint-disable-next-line
  }, [currentQuestion, data]);

  const hotkeys = info && info.hotkeys;
  const handleKeyPress = (e) => {
    const {code} = e;
    const {save_record, pass_poll, collect} = hotkeys;
    if (!isRulesShown && !isOfertaShown && !isPollShown && !isRecordsShown) {
      //save record
      if (code === save_record[0]) {
        setIsPollStarted(false);
        showRecords();
      }
      //pass poll
      if (code === pass_poll[0]) {
        setIsPollStarted(false);
        showPoll();
      }
      //collect
      if (code === collect[0]) {
        info && info.balance > 0 && showCollect();
      }
    }
  };

  React.useEffect(() => {
    if (hotkeys) {
      document.addEventListener('keydown', handleKeyPress);

      return () => {
        document.removeEventListener('keydown', handleKeyPress);
      };
    }
    // eslint-disable-next-line
  }, [info, isRulesShown, isOfertaShown, isPollShown, isRecordsShown]);
  const balance = info?.balance
  return (
    <>
      {info && (
        <MainFooterContainer>
          {isChangeHotkeyShown && (
            <Modal bg={frame}>
              <ChangeHotkey hideChangeHotkey={hideChangeHotkey} />
            </Modal>
          )}
          {isSocialPoll && isRecordsShown && (
            <Modal bg={frame}>
              <Records hideRecords={hideRecords} showPoll={showPoll} />
            </Modal>
          )}
          {isSocialPoll && isPollShown && (
            <Modal bg={frame}>
              {isPollStarted ? (
                <Poll hidePoll={hidePoll} />
              ) : (
                <StartPoll
                  startPoll={() => {
                    dispatch(getNewPoll());
                    setIsPollStarted(true);
                  }}
                  checked={form.isAccepted}
                  onChange={handleInputChange}
                  name="isAccepted"
                />
              )}
            </Modal>
          )}
          {isCollectShown && (
            <Modal bg={popup}>
              <div>
                <Message>CASH BUTTON PRESSED</Message>
                <br />
                <Message style={{fontSize: '7.5vh'}}>
                  {info && info.balance} CREDITS
                </Message>
                <br />
                <Message>WAIT FOR ATTENDANT</Message>
              </div>
            </Modal>
          )}
          {window.innerWidth > 900 && (
            <>
              <FooterImg
                onClick={
                  isSocialPoll
                    ? () => {
                      balance !== '0.00' && setIsPollStarted(false);
                      balance !== '0.00' && showRecords();
                      }
                    : () => dispatch(onChangeSlide(-1, gamesList, sliderCount))
                }
              >
                <img src={footerLeft} alt="" />
                <FooterLeftText
                  disabled={isSocialPoll ? info.balance === '0.00' : false}
                >
                  {isSocialPoll ? (
                    ''
                  ) : (
                    <img
                      style={{
                        transform: 'rotateZ(180deg)',
                        width: '6vh',
                        margin: '0 3vh',
                      }}
                      src={footerArrow}
                      alt="arrowRight"
                    />
                  )}
                  {isSocialPoll ? 'Сохранить рекорд' : 'Назад'}
                </FooterLeftText>
              </FooterImg>
              {objectLength(info && info.game_package_list) !== 1 && (
                <div
                  className="select-provider"
                  style={{cursor: selectedProvider ? 'pointer' : 'default'}}
                  onClick={() => {
                    dispatch(setSelectorProvider(''));
                    dispatch(setSliderCount(0));
                  }}
                >
                  <img
                    src={
                      selectedProvider ? selectProvider : selectProviderDisabled
                    }
                    alt=""
                    style={{width: '40vw', minWidth: '250px', maxHeight: '11vh'}}
                  />
                  <span
                    style={{
                      fontSize: '3vh',
                      textAlign: 'center',
                      opacity: selectedProvider ? '1' : '.5',
                    }}
                  >
                    ВЫБРАТЬ ПРОИЗВОДИТЕЛЯ
                  </span>
                </div>
              )}
              <FooterImg
                onClick={
                  isSocialPoll
                    ? () => {
                        setIsPollStarted(false);
                        showPoll();
                      }
                    : () => {
                        selectedGames === 4 &&
                          setSelectedGames(selectedGames - 4);
                        dispatch(onChangeSlide(1, gamesList, sliderCount));
                      }
                }
              >
                <img src={footerRight} alt="" />
                <FooterRightText>
                  {isSocialPoll ? 'Пройти опрос' : 'Вперед'}
                  {isSocialPoll ? (
                    ''
                  ) : (
                    <img
                      style={{width: '6vh', margin: '0 3vh'}}
                      src={footerArrow}
                      alt="arrowRight"
                    />
                  )}
                </FooterRightText>
              </FooterImg>
            </>
          )}
          {window.innerWidth < 900 && (
            <div className="wrapper">
              <MobileFooterText
                disabled={
                  isSocialPoll ? info.balance === '0.00' : !(sliderCount < 0)
                }
                onClick={
                  isSocialPoll
                    ? () => {
                        setIsPollStarted(false);
                        showRecords();
                      }
                    : () => dispatch(onChangeSlide(-1, allGames, sliderCount))
                }
              >
                {isSocialPoll ? (
                  ''
                ) : (
                  <img
                    src={footerArrow}
                    alt="arrowRight"
                    style={{
                      transform: 'rotateZ(180deg)',
                      width: '26px',
                      margin: '0 1vh',
                    }}
                  />
                )}
                {isSocialPoll ? 'Сохранить рекорд' : 'Назад'}
              </MobileFooterText>
              <MobileFooterText
                disabled={
                  isSocialPoll
                    ? false
                    : !(sliderCount > -(allGames.length / 8) + 1)
                }
                onClick={
                  isSocialPoll
                    ? () => {
                        setIsPollStarted(false);
                        showPoll();
                      }
                    : () => {
                        selectedGames === 4 &&
                          setSelectedGames(selectedGames - 4);
                        sliderCount > -(allGames.length / 8) + 1 &&
                          dispatch(onChangeSlide(1, allGames, sliderCount));
                        console.log(selectedProvider);
                      }
                }
              >
                {isSocialPoll ? 'Пройти опрос' : 'Вперед'}
                {isSocialPoll ? (
                  ''
                ) : (
                  <img
                    src={footerArrow}
                    alt="arrowRight"
                    style={{width: '26px', margin: '0 1vh'}}
                  />
                )}
              </MobileFooterText>
            </div>
          )}
        </MainFooterContainer>
      )}
    </>
  );
};

export default withRouter(MainFooter);
