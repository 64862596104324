import React from 'react';
import {Header, Row, ButtonsWrapper, Button} from '../Menu/styles';

import {useDispatch} from 'react-redux';
import {handlerRemoteIn} from 'redux/authReducer';
import {withRouter} from 'react-router-dom';

const RemoteIn = ({
  currentMenuItem,
  currentSubMenuItem,
  showChangeHotkey,
  setCurrentSubMenuItem,
  info,
  history,
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);

  const handleKeyPress = (e) => {
    const {code} = e;
    const {
      select_first_game,
      select_fifth_game,
      select_third_game,
      select_fourth_game,
      collect,
    } = info && info.hotkeys;

    select_third_game.forEach((item) => {
      if (code === item) {
        setValue(value + 100);
      }
    });
    select_fourth_game.forEach((item) => {
      if (code === item) {
        setValue(value + 1000);
      }
    });

    select_fifth_game.forEach((item) => {
      if (code === item) {
        setValue(value + 10000);
      }
    });

    select_first_game.forEach((item) => {
      if (code === item) {
        setValue(0);
        setCurrentSubMenuItem('');
      }
    });

    collect.forEach((item) => {
      if (code === item) {
        dispatch(handlerRemoteIn(value));
        setValue(0);
        history.push('/authgames');
      }
    });
  };

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
    // eslint-disable-next-line
  }, [value]);

  const denom = info && info.denom;
  return (
    <>
      <Header align={currentSubMenuItem}>
        <span>
          {currentMenuItem}{` > `}{currentSubMenuItem}
        </span>
        <span>Page 1/1</span>
      </Header>
      <div className="service">
        <Row height={7}>
          <span></span>
          <span>VALUE[ ]</span>
          <span>CREDITS</span>
        </Row>
        <Row height={17}>
          <span>REMOTE IN</span>
          <span>{value + '.00'}</span>
          <span>{value / denom + '.00'}</span>
        </Row>
        <Row height={17}>
          <span>CURRENT CREDIT</span>
          <span>{value + '.00'}</span>
          <span>{value / denom + '.00'}</span>
        </Row>
        <ButtonsWrapper>
          <div>
            <Button>
              <span>BACK</span>
            </Button>
          </div>
          <div>
            <Button popup={'100.00'}>
              <span>{100 / denom} CREDITS</span>
            </Button>
            <Button popup={'1000.00'}>
              <span>{1000 / denom} CREDITS</span>
            </Button>
            <Button popup={'10000.00'}>
              <span>{10000 / denom} CREDITS</span>
            </Button>
          </div>
        </ButtonsWrapper>
      </div>
    </>
  );
};

export default withRouter(RemoteIn);
