import {createSlice} from '@reduxjs/toolkit';
import {auth} from 'services';
import {getGame} from '../services/auth';

export const slice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: false,
    status: '',
    loading: false,
    error: null,
    data: null,
    isGame: null,
    records: {
      items: [],
      loading: false,
      error: null,
    },
    authInfo: {
      info: null,
      loading: false,
      error: null,
    },
  },
  reducers: {
    loginIsLoading: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, {payload}) => {
      state.isAuthenticated = true;
      state.loading = false;
      state.error = null;
      state.data = payload;
    },
    loginFailure: (state, {payload}) => {
      state.isAuthenticated = false;
      state.error = payload;
    },
    loginStatus: (state, {payload}) => {
      state.status = payload;
    },
    getInfoIsLoading: (state) => {
      state.authInfo.loading = true;
    },
    getInfoSuccess: (state, {payload}) => {
      state.authInfo.info = payload;
      state.authInfo.loading = false;
      state.authInfo.error = null;
    },
    getInfoFailure: (state, {payload}) => {
      state.authInfo.loading = false;
      state.authInfo.error = payload;
    },
    getRecordsIsLoading: (state) => {
      state.records.loading = true;
    },
    getRecordsSuccess: (state, {payload}) => {
      state.records.items = payload;
      state.records.loading = false;
      state.records.error = null;
    },
    getRecordsFailure: (state, {payload}) => {
      state.records.loading = false;
      state.records.error = payload;
    },
    setInfoBalance: (state, {payload}) => {
      state.authInfo.info.balance = payload;
    },
    setPollSum: (state, {payload}) => {
      state.authInfo.info.poll_sum = payload;
    },
    setInfoDenom: (state, {payload}) => {
      state.authInfo.info.denom = payload;
    },
    setHighscore: (state, {payload}) => {
      state.authInfo.info.highscore = payload;
    },
    setGame: (state, {payload}) => {
      state.isGame = payload;
    },
  },
});

export function getRecords() {
  return async (dispatch) => {
    dispatch(getRecordsIsLoading());

    try {
      const response = await auth.fetchRecords();
      dispatch(getRecordsSuccess(response.data));
    } catch (error) {
      dispatch(getRecordsFailure(error.message));
    }
  };
}
export function getInfo(data) {
  return async (dispatch) => {
    dispatch(getInfoIsLoading());
    if (data) {
      getInfoSuccess(data);
    } else {
      try {
        const response = await auth.fetchInfo();
        dispatch(getInfoSuccess(response.info));
        dispatch(loginStatus(response.status));
      } catch (error) {
        dispatch(getInfoFailure(error.message));
        dispatch(loginStatus(error));
      }
    }
  };
}
export function signUp({password, login}) {
  return async (dispatch) => {
    dispatch(loginIsLoading());

    auth
      .login({password, login})
      .then((res) => {
        if (res.status === 'ok') {
          dispatch(loginSuccess(res));
          dispatch(loginStatus(res.status));
        } else if (res.status === 'error') {
          dispatch(loginFailure(res));
          dispatch(loginStatus(res.status));
        }
      })
      .catch((err) => {
        dispatch(loginFailure(err.massage));
      });
  };
}

export function handlerCollect() {
  return async (dispatch) => {
    auth.collect().then((res) => {
      if (res.status === 'ok') {
        dispatch(setInfoBalance('0.00'));
      }
    });
  };
}

export function handlerRemoteIn(credits) {
  return async (dispatch) => {
    auth.remoteIn(credits).then((res) => {
      if (res.status === 'ok') {
        dispatch(setInfoBalance(res.balance));
        dispatch(setPollSum('0.00'));
      }
    });
  };
}

export const handleGetGame = () => {
  return async (dispatch) => {
    getGame().then((res) => {
      dispatch(setGame(res.game));
    });
  };
};

export const {
  login,
  getInfoFailure,
  getInfoIsLoading,
  getInfoSuccess,
  loginFailure,
  loginSuccess,
  loginIsLoading,
  loginStatus,
  getRecordsFailure,
  getRecordsIsLoading,
  getRecordsSuccess,
  setInfoBalance,
  setPollSum,
  setInfoDenom,
  setHighscore,
  setGame,
} = slice.actions;

export const authSelector = (state) => state.auth;

export default slice.reducer;
