import React from 'react';

import {PageWrapper} from 'components/Common/PageWrapper/index';
import {MainContentWrapper} from 'components/Common/MainContentWrapper/index';
import FiltersBlock from 'components/TablePage/FiltersBlock';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { authSelector, getInfo } from 'redux/authReducer';
import { useSelector, useDispatch } from 'react-redux';
import MainPreloader from 'components/Common/MainPreloader';

const Home = () => {
  const dispatch = useDispatch()
  const {
    status,
  } = useSelector(authSelector);

  React.useEffect(() => {
    status === '' && dispatch(getInfo());
  }, [dispatch, status]);

  return status ? (
    <>
      <Header />
      <PageWrapper margin="30px 0 0 0">
        <MainContentWrapper margin="20px 0 0 0 ">
          <FiltersBlock />
        </MainContentWrapper>
      </PageWrapper>
      <Footer />
    </>
  ) : <MainPreloader/>
}

export default Home

