import React from 'react';

import {StyledNavButton} from './styles';

const NavButton = ({link: {text, to}}) => {
  return (
    <StyledNavButton to={to} exact activeClassName="activeLink">
      <span>{text}</span>
    </StyledNavButton>
  );
};

export default NavButton;
