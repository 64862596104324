import {createSlice} from '@reduxjs/toolkit';
import {denomination} from 'services';

export const slice = createSlice({
  name: 'denomination',
  initialState: {
    isChanged: false,
    loading: false,
    error: null,
  },
  reducers: {
    changeDenomIsLoading: (state) => {
      state.loading = true;
    },
    changeDenomSuccess: (state) => {
      state.isChanged = true;
      state.loading = false;
      state.error = null;
    },
    changeDenomFailure: (state, {payload}) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export function changeDenomination(denomValue) {
  return async (dispatch) => {
    dispatch(changeDenomIsLoading());

    try {
      const response = await denomination.changeDenom(denomValue);
      if (response.status === 'ok') {
        dispatch(changeDenomSuccess());
      }
    } catch (error) {
      dispatch(changeDenomFailure(error.message));
    }
  };
}

export const {
  changeDenomIsLoading,
  changeDenomSuccess,
  changeDenomFailure,
  filterByPoints,
} = slice.actions;

export const recordsSelector = (state) => state.records;

export default slice.reducer;
