import React from 'react';
import {useSelector} from 'react-redux';

import silver from 'assets/images/2.png';
import bronze from 'assets/images/1.png';
import gold from 'assets/images/3.png';
import logo from 'assets/images/logo.png';
import logo2 from 'assets/images/logo2.png';
import jackpot from 'assets/images/jackpot.png';
import points from 'assets/images/points.png';
import frame from 'assets/images/modal.png';

import {
  MainHeader,
  Img,
  Jackpot,
  JackpotText,
  RulesText,
  OfertaText,
  Logo,
  DiamondJackpot,
  DiamondText,
  PointsText,
  Points,
  PointsDigits,
} from './styles';

import {authSelector} from 'redux/authReducer';

import Modal from 'components/AuthGames/Modal';
import Rules from '../../Rules';
import Oferta from '../../Oferta';

const JACKPOTS = [
  {id: 1, name: 'Bronze', img: bronze, color: '#cd7f32',},
  {id: 2, name: 'Silver', img: silver, color: '#c0c0c0'},
  {id: 3, name: 'Gold', img: gold, color: '#ffc200'},
  {id: 4, name: 'Diamond', img: jackpot, color: '#00c2ff'},
]


const diamondJackpot = (arr) => {
  if (arr) {
    const diamond = arr.find((item) => item.name.toUpperCase() === ('Diamond').toUpperCase());
    return diamond;
  }
};

const filterOutDiamond = (arr) => {
  if (arr) {
    return arr.filter((item) => item.name.toUpperCase() !== ('Diamond').toUpperCase()); 
  }
};


const MainHeaderContainer = ({
  isRulesShown,
  hideRules,
  showOferta,
  isOfertaShown,
  hideOferta,
  showRules,
  isSocialPoll,
  showLogo
}) => {
  const {
    authInfo: {info},
  } = useSelector(authSelector);
  const balance = info && info.balance
  const denom = info && info.denom
  const credits = (balance) => {
    if(balance^0 === balance && balance < 1) {
      return balance+'.00'
    } else {
      return (balance).toFixed(2)
    }
  }
  
  return (
    <>
      {isRulesShown && (
        <Modal bg={frame}>
          <Rules hideRules={hideRules} />
        </Modal>
      )}
      {isOfertaShown && (
        <Modal bg={frame}>
          <Oferta hideOferta={hideOferta} />
        </Modal>
      )}
      {info && (
        <MainHeader isSocialPoll={isSocialPoll}>
          <div className="main-header__part left-part" >
            {filterOutDiamond(JACKPOTS).map((item) => {
              const jackpot = info && info.jackpots.find(j => (j.name).toUpperCase() === (item.name).toUpperCase())
              const bank = jackpot ? jackpot.bank : '-'
              return (
                <Jackpot key={item.id} background={item.color}>
                  <Img src={item.img} alt="jackpot" />
                  <JackpotText>
                    Jackpot {item.name}{''}
                    <div className="jackpot-bank">{bank}</div>
                  </JackpotText>
                </Jackpot>
              )
            }

            )}
          </div>
          <div className="main-header__center-part">
            {isSocialPoll && <RulesText onClick={showRules}>Правила</RulesText>}
              <Logo isSocialPoll={isSocialPoll}>
              {isSocialPoll && showLogo !== 1 ? <img src={logo} alt="logo" /> : <img src={logo2} alt="logo" style={{width: '85%', marginLeft: '5%'}}/> }
              </Logo>
            {isSocialPoll && <OfertaText onClick={showOferta}>Оферта</OfertaText>}
          </div>
          <div className="main-header__part right-part">
            <DiamondJackpot>
              <img src={jackpot} alt="jackpot" />
              <DiamondText>
                Jackpot
                <span className="block">
                  {'Diamond'}
                </span>
                <div className="jackpot-bank">
                  {diamondJackpot(info.jackpots) ? diamondJackpot(info.jackpots).bank : '-'}
                </div>
              </DiamondText>
            </DiamondJackpot>
            <PointsText>Очки:</PointsText>
            
            <Points>
              <img src={points} alt="points" />
              <PointsDigits>{credits(balance/denom)} </PointsDigits>
            </Points>
          </div>
        </MainHeader>
      )}
    </>
  );
};

export default MainHeaderContainer;
