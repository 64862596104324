import React from 'react';

import {GameItemContainer, HoverItemButton} from './styles';

const GameItem = ({game: {icon, title, url}}) => {
  return (
    <GameItemContainer>
      <img src={icon} alt={title} />
      <HoverItemButton>
        <a href={url} target="_blank" rel="noopener noreferrer">
          Играть
        </a>
      </HoverItemButton>
    </GameItemContainer>
  );
};

export default GameItem;
