import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {v4 as uuidv4} from 'uuid';

import {
  RecordsContainer,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  FooterCell,
} from './styles';
import {Heading, Button} from '../Rules/styles';

import {authSelector, getRecords} from 'redux/authReducer';
import {saveRecords, recordsSelector} from 'redux/recordsReducer';

const Records = ({hideRecords, showPoll}) => {
  const dispatch = useDispatch();
  const {
    records: {items},
  } = useSelector(authSelector);
  const {
    savedRecord: {data},
  } = useSelector(recordsSelector);
  const {
    authInfo: {info},
  } = useSelector(authSelector);

  React.useEffect(() => {
    dispatch(getRecords());
    info && info.highscore === '0.00' && dispatch(saveRecords(info && info.balance));
  }, [dispatch]);

  const getLastItem = (arr) => {
    if (arr) {
      return arr[arr.length - 1];
    }
  };

  //buttons control 
  const hotkeys = info && info.hotkeys
  const handleKeyPress = e => {
      const { code } = e
      const {
        pass_poll,
      } = hotkeys
      //pass poll
      if(code === pass_poll[0]) {
        hideRecords();
        showPoll();
      }
  }

  React.useEffect(() => {
    if(hotkeys) {
      document.addEventListener("keydown", handleKeyPress);

      return () => {
        document.removeEventListener("keydown", handleKeyPress);
      };
    }
    // eslint-disable-next-line
  }, [info]);

  return (
    <>
      {data && (
        <RecordsContainer>
          <Heading>таблица рекордов игры</Heading>
          <TableContainer>
            <TableHeader>
              <TableHeaderCell>#</TableHeaderCell>
              <TableHeaderCell>ИМЯ</TableHeaderCell>
              <TableHeaderCell>РЕКОРД</TableHeaderCell>
            </TableHeader>
            {data &&
              data.slice(0, 5).map((item, index) => (
                <TableHeader key={uuidv4()}>
                  <TableHeaderCell>{item.position}</TableHeaderCell>
                  <TableHeaderCell>ИМЯ</TableHeaderCell>
                  <TableHeaderCell>{item.value}</TableHeaderCell>
                </TableHeader>
              ))}
            <TableHeader>
              <FooterCell>{items && items.length + 1}</FooterCell>
              <FooterCell>ИМЯ</FooterCell>
              <FooterCell>
                {data.length > 0 && getLastItem(data).value}
              </FooterCell>
            </TableHeader>
          </TableContainer>
          <Button
            onClick={() => {
              hideRecords();
              showPoll();
            }}
          >
            пройти опрос
          </Button>
        </RecordsContainer>
      )}
    </>
  );
};

export default Records;
