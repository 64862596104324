import styled from 'styled-components';
import button from 'assets/images/button.png';

const PollContainer = styled.div`
  position: absolute;
  color: #e1b058;
  /* margin-top: 15%;
  min-height: 80%; */


  @media (max-width: 1024px) {
    border: 3px solid #e1b058;
    border-radius: 10px;
    padding: 5%;
    background: #25132e;
    margin-top: 0;
  }

  @media (max-width: 900px) {
    border: 3px solid #e1b058;
    border-radius: 10px;
    padding: 5% 0;
    background: #25132e;
    min-width: 95vw;
    overflow: auto;
    margin-top: 0%;

    p, li {
      font-size: 12px;
    }
    h3, h4 {
      font-size: 20px;
      margin: 3px 0;
    }
    button {
      font-size: 20px;
    }
  }

  @keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 1s ease-in-out;
}

  .question-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

`;

const QuestionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

const AnswerButton = styled.button`
  width: auto;
  /* height: 50px; */
  padding: 0 20px 5px 20px;
  color: ${(props) => (props.active ? 'white' : '#5a3f1c')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
  font-size: 32px;
  margin: 1%;
  border-image: ${`url(${button})`};
  border-image-slice: 77;
  border-image-width: 50px;
  border-image-outset: 0;
  border-image-repeat: round;
  background: red;
  border-radius: 15px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  text-transform: inh;
  font-weight: bold;
  /* transition: all 0.3s ease; */
  cursor: pointer;
  text-shadow: ${(props) => (props.active ? '2px 4px 6px #7b4310' : 'none')};

  background: ${(props) =>
    props.active
      ? 'linear-gradient(to right,rgba(239, 219, 116, 1) 0%,rgba(239, 219, 116, 1) 19%,rgba(223, 179, 85, 1) 63%,rgba(223, 179, 85, 1) 66%,rgba(224, 180, 86, 1) 100%)'
      : 'linear-gradient(to right, rgba(239, 219, 116, 1) 0%,rgba(239, 219, 116, 1) 8%,rgba(194, 147, 71, 1) 27%,rgba(239, 219, 116, 1) 60%,rgba(194, 147, 71, 1) 86%,rgba(239, 219, 116, 1) 100%)'};

  &:hover {
    color: white;
    text-shadow: 2px 4px 6px #7b4310;
    background: linear-gradient(
      to right,
      rgba(239, 219, 116, 1) 0%,
      rgba(239, 219, 116, 1) 19%,
      rgba(223, 179, 85, 1) 63%,
      rgba(223, 179, 85, 1) 66%,
      rgba(224, 180, 86, 1) 100%
    );
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 1024px) {
    height: 100%;
  }
`;

const Sum = styled.div`
  width: 35%;
  height: 50px;
  background: #0d0612;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: normal;
  border-radius: 10px;
  font-family: 'Candara', sans-serif;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const Subheading = styled.h4`
  font-weight: normal;
  font-size: 28px;
  line-height: 28px;
  text-align: center;
  padding: 2% 0;
  border-bottom: 3px solid #e1b058;
  width: 75%;
  line-height: 37px;
  margin-bottom: 1%;
  font-family: 'Candara', sans-serif;
`;

export {PollContainer, AnswerButton, Sum, Subheading, QuestionContainer};
