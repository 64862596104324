import styled from 'styled-components';

import {NavLink} from 'react-router-dom';

import {media} from 'utils/mediaQueries';

const StyledNavButton = styled(NavLink)`
  width: 203px;
  height: 48px;
  box-shadow: ${(props) => props.theme.navLinkBoxShadow};
  background: ${(props) => props.theme.colors.navLinkBlue};
  color: ${(props) => props.theme.colors.white};
  transform: skewX(-10deg) translateY(4px);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-right: 20px;
  transition: all 0.3 ease;
  text-align: center;

  ${media.tabletLandscape`
    width:152px;
  `}
  ${media.tablet`
    width:125px;
  `}

  ${media.phoneLandscape`
      display: none;
  `}

  &.activeLink {
    background: ${(props) => props.theme.colors.mainBlue};
  }

  &:hover {
    box-shadow: none;
  }

  &:active {
    box-shadow: ${(props) => props.theme.pressedBoxShadow};
  }

  span {
    font-size: ${(props) => props.theme.fontSizes.text};
    line-height: ${(props) => props.theme.lineHeights.text};
    display: block;
    transform: skewX(10deg);
  }

  &:nth-of-type(4) {
    margin-left: 20px;
    transform: skewX(10deg) translateY(4px);
    box-shadow: 4px -4px 0px #ffffff;

    &:hover {
      box-shadow: none;
    }

    &:active {
      box-shadow: ${(props) => props.theme.pressedBoxShadow};
    }
    span {
      transform: skewX(-10deg);
    }
  }
  &:nth-of-type(5) {
    transform: skewX(10deg) translateY(4px);
    box-shadow: 4px -4px 0px #ffffff;

    &:hover {
      box-shadow: none;
    }

    &:active {
      box-shadow: ${(props) => props.theme.pressedBoxShadow};
    }
    span {
      transform: skewX(-10deg);
    }
  }
`;

export {StyledNavButton};
