import {API} from 'settings';

const fetchGames = async () => {
  const URI = `${API}/v1/games?expand=genres&per-page=50`;
  const query = {
    method: 'GET',
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  };

  const response = await fetch(URI, query);
  const json = await response.json();
  return json;
};
export default {
  fetchGames,
};
