import styled from 'styled-components';
import header from 'assets/images/authheader.png';
import header_4_3 from 'assets/images/4_3/header_bg_4_3.jpg';
import newsBgk from 'assets/images/authheadernews.png';

import {resolutions} from '../../../../theme/utils';

const AuthGamesHeader = styled.header`
  width: 100%;
  background: ${`url(${header})`};
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  flex-wrap: nowrap;
  ${resolutions.resolution_4_3} {
    height: 9vh;
    background: ${`url(${header_4_3})`};
    background-size: contain;
  }
  ${resolutions.resolution_16_10} {
    height: 10.5vh;
  }
  .wrapper {
    display: none;

    @media (max-width: 725px) {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    @media (max-width: 520px) {
      flex-direction: row;
    }
  }
`;

const HeaderNews = styled.div`
  width: 70%;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  background: ${`url(${newsBgk})`} no-repeat;
  background-size: 100% 107%;
  font-family: Epson;

  span {
    height: 10px;
    min-width: max-content;
    font-size: 4.5vh;
    color: #f00;
    animation: marquee ${props=> props.time > 0 ? 15 : 15 - 15/100*props.time}s linear infinite;
  }

  @keyframes marquee {
    0% {
      transform: translate(${props=>props.width}px, 0);
    }
    100% {
      transform: translate(-${props=>props.width}px, 0);
    }
  }

  ${resolutions.resolution_16_10} {
    height: 4.875vh;
  }
  ${resolutions.resolution_4_3} {
    height: 3.67vh;
    width: 70%;
  }

  @media (max-width: 1280px) and (max-height: 1024px) {
    width: 60%;
  }
`;

const DenomitationBlock = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  /* margin: 0 1%;*/
  overflow: hidden;
  flex-wrap: nowrap;
  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
`;

const NewsWrapper = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  /* overflow: hidden; */
  .mobile-points {
    display: flex;
  }
  .row {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const DenominationText = styled.span`
  display: inline-block;
  font-style: italic;
  font-weight: bold;
  font-size: 2.8vh;
  color: #e0b662;
  font-weight: bold;
  font-style: italic;
  line-height: 0.699;
  transition: all 0.3s ease;
  margin-left: 2.67vh;

  @media (min-width: 1280px) {
    &:hover {
      text-shadow: 0 0 15px #be00c5, 0 0 10px #be00c5;
      color: white;
    }
  }
  ${resolutions.resolution_4_3} {
    font-size: 1.5625vh;
  }
  ${resolutions.resolution_16_10} {
    font-size: 2.625vh;
  }
`;
const MobileText = styled.span`
  font-style: italic;
  font-weight: bold;
  font-size: 12px;
  color: rgb(225, 182, 98);
  font-weight: bold;
  font-style: italic;
  line-height: 1.099;
  text-align: center;
  display: none;
  border: 2px rgb(225, 182, 98) solid;
  padding: 1%;
  background: #25132e;
  border-radius: 10px;
  min-height: 29px;
  height: 7vh;
  width: inherit;
  max-width: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2px;
`;

const DenominationPoints = styled.div`
  width: 10.25vh;
  margin-left: 2.67vh;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: inherit;
  }

  span {
    position: absolute;
    color: rgb(90, 63, 28);
    font-style: italic;
    font-weight: bold;
    font-size: 25px;
  }
  ${resolutions.resolution_16_10} {
    width: 10.5vh;
    height: 4.875vh;
  }
  ${resolutions.resolution_4_3} {
    width: 7.8vh;
    height: 2.67vh;
  }
`;

const DenominationArrows = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-left:2.13vh;
  margin-right:3.2vh;  */
  margin: 20px;

  @media (max-width: 900px) {
    margin: 0 10px 0 0;
  }
  ${resolutions.resolution_4_3} {
    margin-left: 1vh;
    margin-right: 3vh;
  }
  ${resolutions.resolution_16_10} {
    margin-right: 2.2vh;
  }
`;

const DenominationArrow = styled.div`
  cursor: pointer;
  position: relative;
  @media (min-width: 1280px) {
    &:hover {
      &:after {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }

  &::after {
    width: 50%;
    height: 100%;
    content: '';
    background: rgba(0, 0, 0, 0);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  ${resolutions.resolution_4_3} {
    margin-bottom: -0.2vh;
  }

  img {
    height: 25px;
    object-fit: inherit;

    ${resolutions.resolution_4_3} {
      width: 2.6vh;
      height: 1.56vh;
    }
    ${resolutions.resolution_16_10} {
      height: 2.5vh;
    }
  }
`;

const NewsText = styled.span`
  display: inline-block;
  position: absolute;
  font-size: 51px;
  color: #000;
  font-style: italic;
  left: 5%;
  /* &::after {
    content: '';
    height: 129%;
    width: 2px;
    background: #000;
    left: -10px;
    position: absolute;
    top: -2px;
  } */
`;

const PointsText = styled.span`
  display: inline-block;
  position: absolute;
  font-size: 3.5vh;
  color: #5a3f1c;
  font-style: italic;
  font-weight: bold;

  ${resolutions.resolution_4_3} {
    font-size: 2.3vh;
  }
`;

const MobilePoints = styled.div`
  /* width: %; */
  display: none;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.3%;
  margin-left: 1%;
  position: relative;
  img {
    width: 40%;
  }

  @media (max-width: 414px) {
    display: flex;
  }
`;

const MobilePointsDigits = styled.span`
  display: inline-block;
  position: absolute;
  font-size: 18px;
  color: #000;
  font-style: italic;
  font-weight: bold;
  left: 5%;
  top: -3%;

  @media (max-width: 414px) {
    display: inline-block;
  }
`;

export {
  AuthGamesHeader,
  HeaderNews,
  DenomitationBlock,
  DenominationText,
  DenominationPoints,
  DenominationArrows,
  DenominationArrow,
  NewsWrapper,
  NewsText,
  PointsText,
  MobileText,
  MobilePoints,
  MobilePointsDigits,
};
