import styled from 'styled-components';
import {resolutions} from '../../../../../theme/utils';

const MainContentContainer = styled.main`
  width: 100%;

  .games-list {
    display: flex;
    overflow: hidden;
    /*max-width: 201vh;*/
  } 

  .game {
    z-index: 999999;
    position: relative;
    margin: 0 3vh;
    margin-bottom: -1vh;
    user-select: none;
    -webkit-user-select: none;
  }

  .game:nth-child(4n + 4) {
    margin-right: 0;
  }
  .game:nth-child(5n) {
    margin-bottom: 0;
  }
  .game:nth-child(6n) {
    margin-bottom: 0;
  }
  .game:nth-child(7n) {
    margin-bottom: 0;
  }
  .game:nth-child(8n) {
    margin-bottom: 0;
  }

  .slide {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    min-height: 100%;
    justify-content: center;
    align-items: center;
    padding-left: 10vh;
    padding-right: 10vh;
  }

  .slide-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .game-slides-container {
    display: flex;
    width: 100%;
    min-height: 60vh;
    padding-top: 12vh;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
  }
  .game-slider-button-l {
    width: 10vh;
    height: 6vh;
    transform: rotate(-90deg);
  }
  .game-slider-button-r {
    width: 10vh;
    height: 6vh;
    transform: rotate(-90deg);
  }

  .selected-game {
    filter: drop-shadow(0px 0px 20px #c295cc);
  }
  .selected-provider {
    filter: drop-shadow(0px 0px 20px #fcff4e);
  }
  .selected-label {
    position: absolute;
    transform: translate(-50%, -50%);
    height: 40px;
    width: 130px;
    top: 40%;
    left: 50%;
    z-index: 999;
    font-size: 29px;
    font-weight: 500;
    color: #360a4eed;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 31.25%,
        #fff 100%
      ),
      #b8a772;
    border-radius: 6px;
    box-shadow: 0 0 10px 2px #b8a772;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.9;
  }
  .game-provider {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 18vw;
    height: 13vw;
    margin: 1.5vh 0;
    user-select: none;
    -webkit-user-select: none;
  }
  .game-provider:hover {
    filter: drop-shadow(0px 0px 20px #fcff4e);
  }

  .game-provider > img {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 130%;
    height: 130%;
  }

  .game-slider-button-left {
    position: fixed;
    left: 3vh;
    z-index: 1;
    margin-top: 3vh;
    top: 50%;
  }
  .game-slider-button-right {
    position: fixed;
    right: 3vh;
    z-index: 1;
    margin-top: 3vh;
    top: 50%;
  }
  ${resolutions.resolution_4_3} {
    .games-list {
      margin-top: 2vh;
    }
    .game {
      margin-bottom: 4%;
      margin-right: 1.2vh;
    }
    .game-slider-button-right {
      margin-top: 0;
      right: 0;
    }
    .game-slider-button-left {
      margin-top: 0;
      left: 0;
    }
    .game-provider {
      position: relative;
      width: 30vh;
      height: 23vh;
    }
    .slide {
      padding: 0;
    }
  }

  @media (max-width: 900px) {
    .game-slider-button-left {
      left: -3vh;
    }

    .game-slider-button-right {
      right: -3vh;
    }

    .slide-wrapper {
      max-width: 700px;
    }
  }
  @media (max-width: 1280px) {
    .slide {
      padding: 0;
    }
    .game-provider {
      width: 20vw;
      height: 15vw;
      margin: 0 0.3vh;
    }
  }

  @media (max-width: 1280px) and (max-height: 1024px) {
    .game {
      margin: 0;
    }
    .game-slides-container {
      padding-top: 6vh;
    }
    /* .slide {
      margin-top: 6.5vh;
    } */
  }

  ${resolutions.resolution_16_10} {
    .games-list {
      /* margin-top: 18vh; */
    }
    .game-slider-button-right {
      right: 0;
      margin-top: 4vh;
    }
    .game-slider-button-left {
      margin-top: 4vh;
    }
    .game {
      margin-right: 2vh;
    }
  }

  @media (max-width: 900px) {
    .game-provider {
      position: relative;
      width: 160px;
      height: 118px;
    }
    .game-provider:hover {
      filter: none;
    }

    .game-provider > img {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      width: 130%;
      height: 130%;
      margin: 1vh;
    }

    .slide {
      padding: 0;
      margin: 0;
    }
    .game {
      margin: 0;
    }

    .games-list {
      margin-top: 1vh;
    }
  }

  /* iPhone 5 ----------- */
  @media only screen and (min-width: 320px) and (max-height: 568px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
    .game-provider {
      width: 128px;
      height: 91px;
    }
  }
  @media only screen and (min-width: 320px) and (max-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    .game-provider {
      width: 136px;
      height: 97px;
    }
  }
  /* iPhone 6 ----------- */
  @media only screen and (min-width: 667px) and (max-height: 375px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
    .game-provider {
      width: 154px;
      height: 113px;
    }
  }
  @media only screen and (min-width: 375px) and (max-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    .game-provider {
      width: 154px;
      height: 113px;
    }
  }
`;

const GamesContainer = styled.div`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  display: flex;
`;

const GameContainer = styled.div`
  z-index: 99;
  cursor: pointer;
  position: relative;
  width: 18vw;
  height: 13vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1vh 0;
  &:hover {
    div {
      filter: drop-shadow(0px 0px 20px #c295cc);
    }
  }
  ${resolutions.resolution_4_3} {
    position: relative;
    width: 30vh;
    height: 23vh;
  }
  ${resolutions.resolution_16_10} {
  }
  @media (max-width: 1280px) and (max-height: 1024px) {
    width: 20vw;
    height: 15vw;
    margin: 0 0.5vw;
  }

  .frame {
    width: 100%;
    overflow: hidden;
  }

  @media (max-width: 900px) {
    width: 160px;
    height: 118px;
  }
  /* iPhone 5 ----------- */
  @media only screen and (min-height: 320px) and (max-width: 568px) and (orientation: landscape) {
    width: 128px;
    height: 95px;
  }
  @media only screen and (min-width: 320px) and (max-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    width: 137px;
    height: 100px;
  }

  /* iPhone 6 ----------- */
  @media only screen and (min-width: 667px) and (max-height: 375px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
    width: 154px;
    height: 113px;
  }
  @media only screen and (min-width: 375px) and (max-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    width: 154px;
    height: 113px;
  }
`;

const GameImg = styled.div`
  overflow: hidden;
  position: absolute;
  top: 56%;
  left: 49%;
  transform: translate(-50%, -50%);
  z-index: -5;
  height: 100%;
  transition: all 0.3s ease;
  width: 100%;
  display: flex;

  img {
    width: 100%;
    height: 90%;
    object-fit: fill;
    clip-path: polygon(
      54% 1%,
      95% 21%,
      96% 74%,
      54% 97%,
      38% 92%,
      7% 74%,
      7% 20%
    );
  }
  ${resolutions.resolution_4_3} {
    img {
      width: 100%;
      height: 90%;
      object-fit: fill;
      clip-path: polygon(
        54% 4%,
        94% 23%,
        96% 74%,
        55% 97%,
        38% 92%,
        6% 74%,
        5% 23%
      );
    }
  }

  ${resolutions.resolution_16_10} {
    /* top: 19px; */
  }
`;

export {MainContentContainer, GameContainer, GamesContainer, GameImg};
