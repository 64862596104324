import React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {AuthPageWrapper} from './styles';

import NewsHeaderContainer from './NewsHeader';
import MainBlock from './MainBlock';
import MainFooter from './MainBlock/MainFooter';
import {useShowBlock} from 'hooks/useShowBlock';
import {
  authSelector,
  getInfo,
  setPollSum,
  setInfoBalance,
  setInfoDenom,
  handleGetGame,
  setHighscore,
} from 'redux/authReducer';
import {pollsSelector, getCurrentPoll} from 'redux/pollsReducer';
import {setSelectedActionKey} from 'redux/mainContentReducer';
//provider icons
import igrosoft from 'assets/images/providers/icon1.png';
import novomatic from 'assets/images/providers/icon2.png';
import belatraGames from 'assets/images/providers/icon3.png';
import playtech from 'assets/images/providers/icon4.png';
import roulette from 'assets/images/providers/icon5.png';
import other from 'assets/images/providers/icon6.png';
import milleniumGames from 'assets/images/providers/icon7.png';
import easy7 from 'assets/images/providers/icon8.png';
import newGames2019 from 'assets/images/providers/icon10.png';
import megajack from 'assets/images/providers/icon9.png';

// eslint-disable-next-line react/display-name
const AuthPageContainer = ({pusherClient, history}) => {
  const dispatch = useDispatch();
  const {
    authInfo: {info},
    status,
    isGame,
  } = useSelector(authSelector);
  const {currentPoll, currentQuestion} = useSelector(pollsSelector);

  const InfoBalance = info && info.balance;
  const pollSum = info && info.poll_sum;

  const [selectedGames, setSelectedGames] = React.useState(-4);

  const gameMenu = [
    {
      code: 'Novomatic',
      logo: novomatic,
    },
    {
      code: 'Igrosoft',
      logo: igrosoft,
    },
    {
      code: 'Belatra',
      logo: belatraGames,
    },
    {
      code: 'PlayTech',
      logo: playtech,
    },
    {
      code: 'Easy7',
      logo: easy7,
    },
    {
      code: 'NewGames2019',
      logo: newGames2019,
    },
    {
      code: 'Millenium',
      logo: milleniumGames,
    },
    {
      code: 'Megajack',
      logo: megajack,
    },
    {
      code: 'Roulette',
      logo: roulette,
    },
    {
      code: 'Other',
      logo: other,
    },
  ];

  React.useEffect(() => {
    status === '' && dispatch(getInfo());
  }, [dispatch, status]);

  React.useEffect(() => {
    if (status === 'ok') {
      info === null && dispatch(getInfo());
      !currentPoll.error && dispatch(getCurrentPoll());
    }
    // eslint-disable-next-line
  }, [dispatch, status]);

  React.useEffect(() => {
    if (status === 'ok') {
      dispatch(handleGetGame());
    }
  }, [dispatch, status, info]);

  React.useEffect(() => {
    if (info && info.highscore !== '0.00') {
      showRecords();
    }
  }, [info]);

  React.useEffect(() => {
    if (status === 'ok') {
      info &&
        info.game_list.forEach((item) => {
          if (item.code === isGame) {
            window.location.href = item.url;
          }
        });
    }
  }, [dispatch, status, isGame, info]);

  // REDIRECT IF (info.hall_template !== 'gold')  |---->
  const hall_template = info && info.hall_template;
  React.useEffect(() => {
    if (!!hall_template && hall_template !== 'gold') {
      document.location.href = 'https://nge777.com/';
    }
  }, [info, hall_template]);

  const {
    showBlock: showRecords,
    isBlockShown: isRecordsShown,
    hideBlock: hideRecords,
  } = useShowBlock();

  const {
    showBlock: showPoll,
    isBlockShown: isPollShown,
    hideBlock: hidePoll,
  } = useShowBlock();

  const {
    showBlock: showRules,
    isBlockShown: isRulesShown,
    hideBlock: hideRules,
  } = useShowBlock();

  const {
    showBlock: showOferta,
    isBlockShown: isOfertaShown,
    hideBlock: hideOferta,
  } = useShowBlock();

  const {
    showBlock: showCollect,
    isBlockShown: isCollectShown,
    hideBlock: hideCollect,
  } = useShowBlock();

  const {
    showBlock: showChangeHotkey,
    isBlockShown: isChangeHotkeyShown,
    hideBlock: hideChangeHotkey,
  } = useShowBlock();

  const activeMirror = info && info.active_mirror_block;
  const highscore = info && info.highscore;
  React.useEffect(() => {
    if (info && info.is_social_poll) {
      if (
        activeMirror &&
        currentQuestion === -1 &&
        InfoBalance === '0.00' &&
        pollSum === '0.00' &&
        highscore === '0.00'
      ) {
        hidePoll();
        history.push('/');
      }
    } else {
      if (
        activeMirror &&
        InfoBalance === '0.00' &&
        pollSum === '0.00' &&
        highscore === '0.00'
      ) {
        history.push('/');
      }
    }
  }, [
    info,
    InfoBalance,
    pollSum,
    currentPoll,
    history,
    hidePoll,
    currentQuestion,
    activeMirror,
  ]);

  const social_block = info && info.social_block;
  const isSocialPoll = info && info.is_social_poll;
  const showLogo = info && info.show_logo;
  React.useEffect(() => {
    if (info) {
      const channel = pusherClient.subscribe(
        `private-player-${(info && info.id) || 0}`
      );
      channel.bind('change-player', (data) => {
        if (data.status !== undefined) {
          if (data.status !== 1 && info && info.social_block) {
            history.push('/');
          }
          if (data.status !== 1 && !social_block) {
            window.location.href = 'https://lichess.org/';
          }
        }

        info.poll_sum > 1 && dispatch(setInfoBalance('0.00'));
        if (data.pollscore !== undefined) {
          dispatch(setPollSum('0.00'));
          hidePoll();
        }
        data.balance !== undefined && dispatch(setInfoBalance(data.balance));
        data.denomination !== undefined &&
          dispatch(
            setInfoDenom(data.denomination === 1 ? '1.00' : data.denomination)
          );
      });

      channel.bind('action-player', (data) => {
        if (data[0] === 'collect') {
          if (isCollectShown) {
            hideCollect();
            history.push('/collect');
          } else {
            history.push('/audit');
          }
        }
      });

      channel.bind('keyboard', (data) => {
        if (data) {
          if (data.cmd === 'listen-key') {
            showChangeHotkey();
            dispatch(setSelectedActionKey(data.action_id));
          }
        }
      });

      return () =>
        pusherClient &&
        function () {
          channel.unbind('change-player');
          channel.unbind('action-player');
          channel.unbind('keyboard');
        };
    }
  }, [
    dispatch,
    info,
    pusherClient,
    hidePoll,
    history,
    social_block,
    hideCollect,
    isCollectShown,
    showChangeHotkey,
  ]);

  return (
    <AuthPageWrapper>
      <NewsHeaderContainer
        showRules={showRules}
        showOferta={showOferta}
        isRulesShown={isRulesShown}
        isOfertaShown={isOfertaShown}
        isPollShown={isPollShown}
        isRecordsShown={isRecordsShown}
        isSocialPoll={isSocialPoll}
      />
      <MainBlock
        showRecords={showRecords}
        showPoll={showPoll}
        showRules={showRules}
        isRulesShown={isRulesShown}
        hideRules={hideRules}
        showOferta={showOferta}
        isOfertaShown={isOfertaShown}
        hideOferta={hideOferta}
        isSocialPoll={isSocialPoll}
        showLogo={showLogo}
        isPollShown={isPollShown}
        isRecordsShown={isRecordsShown}
        gameMenu={gameMenu}
        setSelectedGames={setSelectedGames}
        selectedGames={selectedGames}
        showChangeHotkey={showChangeHotkey}
        isChangeHotkeyShown={isChangeHotkeyShown}
      />
      <MainFooter
        showRecords={showRecords}
        isRecordsShown={isRecordsShown}
        hideRecords={hideRecords}
        showPoll={showPoll}
        isPollShown={isPollShown}
        hidePoll={hidePoll}
        isSocialPoll={isSocialPoll}
        isRulesShown={isRulesShown}
        isOfertaShown={isOfertaShown}
        gameMenu={gameMenu}
        setSelectedGames={setSelectedGames}
        selectedGames={selectedGames}
        showCollect={showCollect}
        isCollectShown={isCollectShown}
        isChangeHotkeyShown={isChangeHotkeyShown}
        hideChangeHotkey={hideChangeHotkey}
      />
    </AuthPageWrapper>
  );
};

export default AuthPageContainer;
