import styled from 'styled-components';
import {resolutions} from '../../../../../theme/utils';

const MainFooterContainer = styled.footer`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: auto;
  font-family: 'Colus';
  position: fixed;
  bottom: 0;
  z-index: 99999999;

  .select-provider {
    position: relative;
    width: max-content;
    height: max-content;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .select-provider > span {
    color: #7a0000;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 55%;
    left: 50%;
    width: max-content;
    font-size: 3.3vh;
  }

  .select-provider > img {
    width: 120%;
  }

  .wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }


  @media (max-width: 900px) {
    .select-provider > span {
      font-size: 10px;
      width: 100px;
    }
    .select-provider > img {
      width: 200px;
    }
  }
`;

const FooterImg = styled.div`
  width: 33%;
  height: 8vh;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  position: relative;
  z-index: 10;
  img {
    width: 100%;
    height: 100%;
  }
  ${resolutions.resolution_4_3} {
    height: 8vh;
  }
`;

export const FooterBtnLabel = styled.span`
    opacity: ${props => props.selectedProvider ? '1' : '.5'};
    text-align: 'center';
    line-height: '13px';
    font-size: '6vh';
`

const FooterLeftText = styled.span`
  font-size: 90%;
  color: ${(props) =>
    props.disabled ? 'rgba(225, 176, 88, 0.5)' : '#e1b058'};
  line-height: 1;
  position: absolute;
  top: 45%;
  left: 15%;
  transition: all 0.3s ease;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  cursor: pointer;
  transition: .5s;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1280px) {
    font-size: 2.4vh;
    &:hover {
      text-shadow: 0 0 15px #be00c5, 0 0 10px #be00c5;
      color: white;
    }
  }
  ${resolutions.resolution_4_3} {
    font-size: 2.5vh;
    margin-top: 0.5vh;
    left: 10%;
  }
  ${resolutions.resolution_16_10} {
    font-size: 3vh;
    top: 50%;
    left: 10%;
  }

  @media (max-width: 1280px) and (max-height: 1024px) {
    left: 10%;
  }
  
`;
const FooterRightText = styled.span`
  font-size: 90%;
  color: ${(props) =>
    props.disabled ? 'rgba(225, 176, 88, 0.5)' : '#e1b058'};
  line-height: 1;
  position: absolute;
  top: 42%;
  left: 39%;
  transition: all 0.3s ease;
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  transition: .5s;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1280px) {
    font-size: 2.4vh;
    &:hover {
      text-shadow: 0 0 15px #be00c5, 0 0 10px #be00c5;
      color: white;
    }
  }
  
  ${resolutions.resolution_16_10} {
    font-size: 3vh;
    top: 50%;
    left: 35%;
  }
  ${resolutions.resolution_4_3} {
    font-size: 2.5vh;
    margin-top: 0.5vh;
  }

`;

const Wheel = styled.div`
  width: 34%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  img {
    width: 100%;
  }
`;

const WheelText = styled.span`
  font-size: 5vh;
  line-height: 1;
  position: absolute;
  color: rgb(40, 24, 47);
  text-transform: uppercase;
  top: 6.5vh;
  ${resolutions.resolution_4_3} {
    font-size: 4.5vh;
    top: 5.5vh;
  }
 
`;

const MobileFooterText = styled.span`
  font-style: italic;
  font-weight: bold;
  font-size: 100%;
  color: rgb(225, 182, 98);
  line-height: 0.699;
  border: 2px rgb(225, 182, 98) solid;
  background: #25132e;
  border-radius: 10px;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: ${props => props.disabled ? '.5':'1'};
`;

const Arrow = styled.div`
  position: absolute;
  top: 0;
  width: 50%;
  z-index: -1;
  top: -28%;
  position: absolute;
  left: 26%;
  transition: all 0.2s ease;
  transform: ${(props) => {
    if (props.x === 50) return `translate(0%, 0%) rotate(0deg)`;
    if (props.x > 70) return `translate(50%,9%) rotate(33deg)`;
    if (props.x < 30) return `translate(-53%,9%) rotate(-32deg)`;
    if (props.x > 50) return `translate(29%,2%) rotate(19deg)`;
    if (props.x < 50) return `translate(-29%,1%) rotate(-19deg)`;
  }};

  img {
    width: 100%;
  }
`;

export const Message = styled.span`
  text-align: center;
  width: 100%;
  font-size: 5vh;
  line-height: 8vh;
  text-transform: uppercase;
  background: linear-gradient(180deg, rgba(250,242,161,1) 0%, rgba(207,127,34,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #0B2349;
  display: table;
  filter: drop-shadow(0 6px 4px #0000007d);
`

export {
  MainFooterContainer,
  FooterImg,
  FooterLeftText,
  FooterRightText,
  Wheel,
  WheelText,
  MobileFooterText,
  Arrow,
};
