import React from 'react';
import {Switch, Route} from 'react-router-dom';

import Home from 'pages/Home';
import News from 'pages/News';
import YouthPolitics from 'pages/YouthPolitics';
import Table from 'pages/Table';
import AuthGames from 'pages/AuthGames';
import Audit from 'pages/Audit';
import Collect from 'pages/Collect'

import Pusher from './utils/pusher';

Pusher.Runtime.createXHR = function () {
  var xhr = new XMLHttpRequest();
  xhr.withCredentials = true;
  return xhr;
};

const pusherClient = new Pusher('e97a045d9e4c3138bbb0', {
  cluster: 'eu',
  forceTLS: true,
  authEndpoint: 'https://nge777.com/pusher/auth?socket=1',
});



const App = () => {
  return (
    <>
      <Switch>
        <Route exact path="/"><Home pusherClient={pusherClient}/></Route>
        <Route path="/news" component={News} />
        <Route path="/youthpolitics" component={YouthPolitics} />
        <Route path="/table" component={Table} />
        <Route path="/authgames" ><AuthGames pusherClient={pusherClient}/></Route>
        <Route path="/audit" ><Audit/></Route>
        <Route path="/collect"><Collect/></Route>
      </Switch>
    </>
  );
};

export default App;
