import React from 'react';
import {Link} from 'react-router-dom';

import {NavBarContainer} from './styles';

import {ReactComponent as Logo} from 'assets/images/logo.svg';
import NavButton from 'components/Header/NavButtton';
import Button from 'components/Common/Button';

const NAV_LINKS_LEFT = [
  {id: 1, text: 'Игры', to: '/'},
  {id: 2, text: 'Новости', to: '/news'},
];

const NAV_LINKS_RIGHT = [
  {id: 3, text: 'Молодёжная политика', to: '/youthpolitics'},
  {id: 4, text: 'Турнирная таблица', to: '/table'},
];

const NavBar = ({ handleOpen, status }) => {
  return (
    <NavBarContainer>
      {NAV_LINKS_LEFT.map((link) => (
        <NavButton link={link} key={link.id} />
      ))}
      <Link to="/">
        <Logo className="logo" />
      </Link>
      {NAV_LINKS_RIGHT.map((link) => (
        <NavButton link={link} key={link.id} />
      ))}
      {status !== 'ok' ? <Button text="Вход" onClick={handleOpen} className="logbutton" /> : ''}
    </NavBarContainer>
  );
};

export default NavBar;
