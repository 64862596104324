import React from 'react';

import {useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom';

import AuthPageContainer from 'components/AuthGames/AuthPageContainer';

import {authSelector} from 'redux/authReducer';

const AuthGames = ({history, pusherClient}) => {
  const {
    authInfo: {info},
  } = useSelector(authSelector);
 
  React.useEffect(() => {
    if (info === undefined) {
      history.push('/');
    }
  }, [info, history])

  return <AuthPageContainer pusherClient={pusherClient} history={history}/>;
};

export default withRouter(AuthGames);
